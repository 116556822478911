$grid-gutter-width: 20px !default;

$grid-columns: 12 !default;
$sides: "right", "left", "both";

// Base column styles
@mixin make-columns-base() {
    [class^="grid-col"], [class*=" grid-col"],
    [class^="grid-tiles"] > li,
    [class*=" grid-tiles"] > li {
        float: left;
        min-height: 1px;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        width: 100%;
        margin-left: 0;
    }
}

// Column Variable guttering
@mixin make-variable-guttering() {
    @each $gutter-width in $grid-gutter-widths {
        $gutter-width-name: nth($gutter-width, 1);
        $gutter-width-px: nth($gutter-width, 2);

        .grid-row.grid-row-gutter-#{$gutter-width-name} {
            margin-left: ($gutter-width-px / -2);
            margin-right: ($gutter-width-px / -2);

            [class^="grid-col"], [class*=" grid-col"] {
                padding-left: ($gutter-width-px / 2);
                padding-right: ($gutter-width-px / 2);
            }
        }
    }
}

// Base styles for grid container
@mixin make-container-base() {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    @include clearfix-micro();
}

@mixin make-container-from-s() {
    @include mq($from: s) {
        // The grid here is 700px for the main content, but contains a 728px banner
        // Therefore we've agreed to make all content fit to 728px. (768px - 2*20px)
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }
}

@mixin make-container-from-m() {
    @include mq($from: m) {
        // The grid here is 700px for the main content, but contains a 728px banner
        // Therefore we've agreed to make all content fit to 728px. (768px - 2*20px)
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }
}

@mixin make-container-from-l() {
    @include mq($from: l) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        max-width: 1024px;
    }
}

@mixin make-container-from-xl() {
    @include mq($from: xl) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        max-width: 1280px;
    }
}

@mixin make-container-from-xxl() {
    @include mq($from: xxl) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        max-width: 1350px;
    }
}

// Styles for grid container across breakpoints
@mixin make-container() {
    @include make-container-base();
    @include make-container-from-s();
    @include make-container-from-m();
    @include make-container-from-l();
    @include make-container-from-xl();
    @include make-container-from-xxl();
}

// Base styles for grid-row
@mixin make-row($gutter: $grid-gutter-width) {
    margin-left: -($gutter/2);
    margin-right: -($gutter/2);
    @include clearfix-micro();
}

@mixin shared-column() {
    right: auto;
    left: auto;
}

@mixin make-column($columns) {
    width: percentage(($columns / $grid-columns));
}

@mixin make-column-offset($columns) {
    margin-left: percentage(($columns / $grid-columns));
}

@mixin make-column-push($columns) {
    position: relative;
    left: percentage(($columns / $grid-columns));

}

@mixin make-column-pull($columns) {
    position: relative;
    right: percentage(($columns / $grid-columns));
}

@mixin make-columns($name: "") {

    @if $name != "" {
        $name: '-' + $name;
    }

    $gridClasses: "";
    @for $i from 1 through $grid-columns {
        $gridClasses: $gridClasses + ".grid-col#{$name}-#{$i}";
        $gridClasses: $gridClasses + ",";
    }

    #{$gridClasses} {
        @include shared-column();
    }

// Change this column to be floated right at certain sizes
    .grid-col#{$name}-right {
        float: right;
    }

// Overide the above if you need to reset the columns on higher sizes
    .grid-col#{$name}-left {
        float: left;
    }

    @for $i from 1 through $grid-columns {

        .grid-col#{$name}-#{$i},
        .grid-tiles#{$name}-#{$i} > li {
            @include make-column($i);
        }

        .grid-tiles#{$name}-first-#{$i} > li:first-of-type {
            @include make-column($i);
        }

    //TODO : Make these optional via the mixin

        .grid-col#{$name}-offset-#{$i} {
            @include make-column-offset($i);
        }

        .grid-col#{$name}-push-#{$i} {
            @include make-column-push($i);
        }

        .grid-col#{$name}-pull-#{$i} {
            @include make-column-pull($i);
        }

    }
//Create flush classes [remove gutter padding] for horizontal sides at each breakpoint
    @each $breakpoint in $mq-breakpoints {
        $breakpoint-name: nth($breakpoint, 1);
        @include mq($from: #{$breakpoint-name}) {
            @each $side in $sides {
                @if $side == "both"{
                    .grid-#{$breakpoint-name}-flush-both {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .grid-#{$breakpoint-name}-gutter-both {
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;
                    }
                } @else {
                    .grid-#{$breakpoint-name}-flush-#{$side} {
                        padding-#{$side}: 0;
                    }
                    .grid-#{$breakpoint-name}-gutter-#{$side} {
                        padding-#{$side}: $grid-gutter-width/2;
                    }
                }
            }
        }
    }
}

@mixin make-columns-by-mq($mqArray) {
    @each $breakpoint in $mq-breakpoints {
        $breakpoint-name: nth($breakpoint, 1);
        @if $breakpoint-name == "xs" {
            @include make-columns();
        } @else {
            @include mq($from: #{$breakpoint-name}) {
                @include make-columns($breakpoint-name);
            }
        }
    }
}

@mixin make-columns-by-mq-ie8($mqArray) {
    @each $breakpoint in $mq-breakpoints {
        $breakpoint-name: nth($breakpoint, 1);

        @if $breakpoint-name == "xs" {
            @include make-columns();
        } @else {
            @include make-columns($breakpoint-name);
        }

    }
}

//TODO should this be extend classes or actual classes, these could get a lot of use, might break the limit

%grid-component-container {
    padding: $grid-gutter-width*0.6 $grid-gutter-width/2;

    @include mq($from: m) {
        padding: $grid-gutter-width*0.6 $grid-gutter-width;
    }
}

%grid-component-container-h {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

%grid-component-container-v {
    padding-top: $grid-gutter-width*0.6;
    padding-bottom: $grid-gutter-width*0.6;
}

%grid-component-container-no-top {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-bottom: $grid-gutter-width*0.6;
}

%grid-component-container-no-bottom {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: $grid-gutter-width*0.6;
}
