// @category Utils
// @name Border
// @description Add borders to elements through class name pattern .border-{a all, v vertical, h horizontal, t top, b bottom, l left, r right}
//
// @markup
// <div class="border-a space-mbm">(.border-a) Border all</div>
// <div class="border-v space-mbm">(.border-v) Border all</div>
// <div class="border-h space-mbm">(.border-h) Border all</div>
// <div class="border-t space-mbm">(.border-t) Border all</div>
// <div class="border-b space-mbm">(.border-b) Border all</div>
// <div class="border-l space-mbm">(.border-l) Border all</div>
// <div class="border-r space-mbm">(.border-r) Border all</div>
// <div class="border-from-m-a space-mbm">(.border-a) Border all from m</div>

.border-a {
    border: solid 1px $colourBorder;
}

.border-v {
    border-top: solid 1px $colourBorder;
    border-bottom: solid 1px $colourBorder;
}

.border-h {
    border-left: solid 1px $colourBorder;
    border-right: solid 1px $colourBorder;
}

.border-l {
    border-left: solid 1px $colourBorder;
}

.border-r {
    border-right: solid 1px $colourBorder;
    &.border-light {
        border-right: solid 1px $colourBorderLight;
    }
}

.border-t {
    border-top: solid 1px $colourBorder;
}

.border-b {
    border-bottom: solid 1px $colourBorder;
}

.border-n.border-n {
    border: none;
}

$border-keys: ("a": '', "v": -top -bottom, "h": -left -right, "t": -top, "r": -right, "b": -bottom, "l": -left);
$locations: (a, v, h, t, r, b, l);
$tShirtSizes: (s, m, l, xl, xxl); // xs is not in list as it will never be used

@each $size in $tShirtSizes {
    @each $location in $locations {
        $keys: map_get($border-keys, $location);
        @include mq($from: #{$size}) {
            .border-from-#{$size}-#{$location} {
                @if (length($keys) == 2) {
                    border#{nth($keys, 1)}: solid 1px $colourBorder;
                    border#{nth($keys, 2)}: solid 1px $colourBorder;
                } @else {
                    border#{nth($keys, 1)}: solid 1px $colourBorder;
                }
            }
        }
    }
}
