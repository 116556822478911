body {
    color: $colourTxtPrimary;
    font-family: $baseFontStack;
    font-size: $baseFontSize;
    line-height: $baseLineHeight;
    .critical-fonts-loaded & {
        font-family: $mainFontStack;
    }
}

//
// Base styles for all Headers h1, h2 etc
//
%hx {
    font-style: normal;
    font-weight: $fontBoldWeight;
    font-family: inherit;
    margin: 0 0 $mSpacingPx;
    letter-spacing: 0.02em;
}

// @category Typography
// @name Header 1
// @description The primary header for a page
// @exampleextrasmall
// @deprecated
//
// @markup
// <h1>Main heading</h1>
// <h3 class="h1">Semantically h3, styled as h1</h3>

h1,
.h1 {
    @extend %hx;
    font-size: 20px;
    margin-bottom: $mSpacingPx;
}

.hero h1,
h1.hero {
    @extend %hx;
    font-family: "Readex Pro", sans-serif;
    font-size: $fontSizeHero;
    font-weight: $fontBoldWeight;
}

// @category Typography
// @name Header 2
// @description The secondary header for a page
// @exampleextrasmall
// @deprecated
//
// @markup
// <h2>Secondary heading</h2>
// <h3 class="h2">Semantically h3, styled as h2</h3>

h2,
.h2 {
    @extend %hx;
    font-size: 18px;
}

// @category Typography
// @subcategory Headers
// @name Header 3
// @description Third level header
// @exampleextrasmall
// @deprecated
//
// @markup
// <h3>Third level heading</h3>
// <h2 class="h3">Semantically h2, styled as h3</h3>

h3,
.h3 {
    @extend %hx;
    font-size: 17px;
}

// @category Typography
// @name Header 4
// @description Fourth level header
// @exampleextrasmall
// @deprecated
// @warning header 4 is bigger than header 3 and 2, and only 1 px smaller than header 1, there does not seem to be a lot of visual distinction and size ordering feels wrong semantically
//
// @markup
// <h4>Fourth level heading</h4>
// <h5 class="h4">Semantically h5, styled as h4</h5>

h4,
.h4 {
    @extend %hx;
    font-size: 15px;
}

// @category Typography
// @name Header 1
// @description The primary header for a page
// @exampleextrasmall
//
// @markup
// <h1 class="h1-responsive">Main heading</h1>

.h1-responsive {
    font-size: 20px;
    margin-bottom: 14px;
}

@include mq($from: m) {
    .h1-responsive {
        font-size: 24px;
        margin-bottom: 18px;
    }
}
// @category Typography
// @name Header 2
// @description The secondary header for a page
// @exampleextrasmall
//
// @markup
// <h2 class="h2-responsive">Secondary heading</h2>

.h2-responsive {
    font-size: 18px;
    margin-bottom: 14px;
}

@include mq($from: m) {
    .h2-responsive {
        font-size: 20px;
        margin-bottom: 18px;
    }
}

// @category Typography
// @subcategory Headers
// @name Header 3
// @description Third level header
// @exampleextrasmall
//
// @markup
// <h3 class="h3-responsive">Third level heading</h3>

.h3-responsive {
    font-size: 16px;
    margin-bottom: 8px;
}

@include mq($from: m) {
    .h3-responsive {
        font-size: 18px;
        margin-bottom: 8px;
    }
}

// @category Typography
// @name Header 4
// @description Fourth level header
// @exampleextrasmall
// @warning header 4 is bigger than header 3 and 2, and only 1 px smaller than header 1, there does not seem to be a lot of visual distinction and size ordering feels wrong semantically
//
// @markup
// <h4 class="h4-responsive">Fourth level heading</h4>

.h4-responsive {
    font-size: 15px;
    margin-bottom: 8px;
}

// @category Typography
// @name Header Underline
// @description Adds a nice underline to a header element
// @exampleextrasmall
//
// @markup
// <h2 class="h-underline-xs">Give me an extra small-padded underline</h2>
// <h2 class="h-underline-s">Give me a small-padded underline</h2>
// <h2 class="h-underline-m">Give me a medium-padded underline</h2>

%h-underline {
    border-bottom: 1px solid $bark_20pc;
    padding-bottom: $xsSpacingPx;
    margin: 0;
}

.h-underline-zero {
    @extend %h-underline;
}

.h-underline-xs {
    @extend %h-underline;
    margin-bottom: $xsSpacingPx;
}

.h-underline-s {
    @extend %h-underline;
    margin-bottom: $sSpacingPx;
}

.h-underline-m {
    @extend %h-underline;
    margin-bottom: $mSpacingPx;
}

.h-underline-dashed {
    border-bottom-style: dashed;
}

strong {
    font-weight: $fontBoldWeight;
}

// @category Typography
// @name Paragraph Text
// @description Default Paragraph text
// @exampleextrasmall
//
// @markup
// <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue.</p>
// <p>Praesent a dignissim metus, in venenatis lorem.</p>

p {
    font-size: $baseFontSize;
    margin: 0 0 $mSpacingPx;
}

// @category Typography
// @name Anchor Tag
// @description Default Link
// @exampleextrasmall
//
// @state :hover   - Highlight the button when hovered
// @state :active  - Active state
//
// @markup
// <a href="#">Link</a>

.link,
a {
    text-decoration: none;
    color: $colourLink;
    cursor: pointer;

    .no-touchevents &:hover {
        text-decoration: underline;
    }
}

// @category Typography
// @name Definition list
// @description Default settings for definition lists
// @exampleextrasmall
//
// @markup
// <dl>
//   <dt>Definition term 1</dt><dd>Definition description 1</dd>
//   <dt>Definition term 2</dt><dd>Definition description 2</dd>
// </dl>

dl {
    margin-bottom: $baseLineHeightPx;
}

dt,
dd {
    line-height: $baseLineHeight;
    min-height: $baseLineHeightPx;
    margin: 0;
}

// @category Typography
// @name Subtext
// @description Used for presenting smaller text that is less important than
// @exampleextrasmall
//
// @markup
// <p class="txt-sub">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue.</p>

.txt-sub {
    font-size: $fontSizeSub;
}

// @category Typography
// @name Mircotext
// @description Used for presenting even smaller text that is less important
// @exampleextrasmall
// @info Use with caution, not good for accesability
//
// @markup
// <p class="txt-micro">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue.</p>
.txt-micro {
    font-size: $fontSizeMicro;
    letter-spacing: $fontSizeMicroKerning;
}

// @category Typography
// @name Normal text
// @description Set text weight to normal
// @exampleextrasmall
//
// @markup
// <h1 class="txt-normal">.txt-normal</h1>

.txt-normal {
    font-weight: $fontNormalWeight;
}

// @category Typography
// @name Emphasis text
// @description Text to be emphasised
// @exampleextrasmall
//
// @markup
// <p class="txt-emphasis">.txt-emphasis</p>

.txt-emphasis {
    font-weight: $fontBoldWeight;
}

// @category Typography
// @name Hero text
// @description Text to be made large enough for a hero panel
// @exampleextrasmall
//
// @markup
// <p class="txt-hero">.txt-hero</p>

.txt-hero {
    font-size: $fontSizeHero;
}

// @category Typography
// @name Large text
// @description Text to be made large
// @exampleextrasmall
//
// @markup
// <p class="txt-large">.txt-large</p>

.txt-large {
    font-size: $fontSizeLarge;
}

// @category Typography
// @name Extra-large text
// @description Text to be made extra-large
// @exampleextrasmall
//
// @markup
// <p class="txt-xlarge">.txt-large</p>

.txt-xlarge {
    font-size: $fontSizeXLarge;
}

// @category Typography
// @name Important Highlighted text
// @description Text is of high importance
// @exampleextrasmall
// @warning to close to .txt-error
//
// @markup
// <p class="txt-important">.txt-important</p>

.txt-important {
    color: $importantColour;
}

// @category Typography
// @name Info Highlighted text
// @description Text which is highlighted as being imformative
// @exampleextrasmall
//
// @markup
// <p class="txt-info">.txt-info</p>

.txt-info {
    color: $infoColour;
    font-weight: $fontBoldWeight;
}

// @category Typography
// @name Primary color
// @description Gumtree primary txt colour
// @exampleextrasmall
//
// @markup
// <p class="txt-primary">.txt-primary</p>

.txt-primary {
    color: $colourTxtPrimary;
}

// @category Typography
// @name Secondary color
// @description Gumtree secondary txt colour
// @exampleextrasmall
//
// @markup
// <p class="txt-secondary">.txt-secondary</p>

.txt-secondary {
    color: $colourTxtSecondary !important;
}

// @category Typography
// @name Tertiary color
// @description Gumtree tertiary txt colour
// @exampleextrasmall
//
// @markup
// <p class="txt-tertiary">.txt-tertiary</p>

.txt-tertiary {
    color: $colourTxtTeritary;
}

// @category Typography
// @name Quaternary color
// @description Gumtree quaternary txt colour
// @exampleextrasmall
//
// @markup
// <p class="txt-quaternary">.txt-quaternary</p>

.txt-quaternary {
    color: $colourTxtQuaternary;
}

// @category Typography
// @name Highlight color
// @description Gumtree text highlight
// @exampleextrasmall
//
// @markup
// <p class="txt-highlight">.txt-quaternary</p>

.txt-highlight {
    color: $colourTxtSelected;
}

// @category Typography
// @name Success colour text
// @description Text with a positive message
// @exampleextrasmall
//
// @markup
// <p class="txt-success">.txt-success</p>

.txt-success {
    color: $successColour;
}

// @category Typography
// @name Error Highlighted text
// @description Text with a error message
// @exampleextrasmall
//
// @markup
// <p class="txt-error">.txt-error</p>

.txt-error {
    color: $colourTxtError;
}

// @category Typography
// @name Positive Highlighted text
// @description Text coloured to indicated success
// @exampleextrasmall
//
// @markup
// <p class="txt-selected">.txt-selected</p>
.txt-selected {
    color: $successColour;
}

// @category Typography
// @name Warning Highlighted text
// @description Text which is warning, a level lower than important
// @exampleextrasmall
//
// @markup
// <p class="txt-warning">.txt-warning</p>

.txt-warning {
    color: $warningColour;
}

// @category Typography
// @name Berry coloured text
// @description Text which is warning, a level lower than important
// @exampleextrasmall
//
// @markup
// <p class="txt-warning">.txt-warning</p>

.txt-berry {
    color: $berry;
}

// @category Typography
// @name Berry coloured text
// @description Text which is warning, a level lower than important
// @exampleextrasmall
//
// @markup
// <p class="txt-agnosticRed">.txt-agnosticRed</p>

.txt-agnosticRed {
    color: $agnosticRed;
}

// @category Typography
// @name Berry coloured text
// @description Text which is warning, a level lower than important
// @exampleextrasmall
//
// @markup
// <p class="txt-blade">.txt-blade</p>

.txt-blade {
    color: $blade;
}

// @category Typography
// @name Faded text
// @description Knocked back text colour
// @exampleextrasmall
//
// @markup
// <p class="txt-faded">.txt-faded</p>

.txt-faded {
    color: $branch_10pc;
}

// @category Typography
// @name Center align text
// @exampleextrasmall
//
// @markup
// <p class="txt-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-center {
    text-align: center;
}

// @category Typography
// @name Left align text
// @exampleextrasmall
//
// @markup
// <p class="txt-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-left {
    text-align: left;
}

// @category Typography
// @name Right align text
// @exampleextrasmall
//
// @markup
// <p class="txt-right">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-right {
    text-align: right;
}

// @category Typography
// @name Align text by mq
// @exampleextrasmall
//
// @markup
// <p class="txt-right-from-m">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>
// <p class="txt-left-from-m">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

@each $breakpoint in $mq-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);
    @include mq($from: #{$breakpoint-name}) {
        .txt-center-from-#{$breakpoint-name} {
            text-align: center;
        }

        .txt-left-from-#{$breakpoint-name} {
            text-align: left;
        }

        .txt-right-from-#{$breakpoint-name} {
            text-align: right;
        }
    }
}

// @category Typography
// @name Top align text
// @exampleextrasmall
//
// @markup
// <p class="txt-top">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-top {
    vertical-align: top;
}

// @category Typography
// @name Bottom align text
// @exampleextrasmall
//
// @markup
// <p class="txt-bottom">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-bottom {
    vertical-align: bottom;
}

// @category Typography
// @name Middle align text
// @exampleextrasmall
//
// @markup
// <p class="txt-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend lobortis dolor pretium congue. Praesent a dignissim metus, in venenatis lorem.</p>

.txt-middle {
    vertical-align: middle;
}

// @category Typography
// @name Capitlize first letter of text and leave rest as is as its not a clever capitlize
// @exampleextrasmall
//
// @markup
// <p class="txt-capitilize">lorem ipsum</p>

.txt-capitilize {
    text-transform: capitalize;
}

// @category Typography
// @name Horizontal Rule
// @exampleextrasmall
//
// @markup
// <hr/>

%hr,
hr,
.hr {
    border: 0;
    height: 1px;
    background: $colourHrBg;
    margin-bottom: $mSpacingPx;
    margin-top: 0;
}

// @category Typography
// @name Dark Horizontal Rule
// @exampleextrasmall
//
// @markup
// <hr class="hr-dark" />
.hr-dark {
    @extend %hr;
    background: $myrtle_dark;
}

// @category Typography
// @name Information link
// @exampleextrasmall
//
// @markup
// <a class="txt-link ">.txt-link</a>

// TODO: Is this overide the wrong way around, are we using this more than we would be for just overiding things like header and side menu
.txt-link {
    text-decoration: none;
    color: $colourLink;
    cursor: pointer;
    .no-touchevents &:hover {
        text-decoration: underline;
    }
}

// @category Typography
// @name Standalone piece of text
// @exampleextrasmall
//
// @markup
// <a class="txt-orphan ">.txt-orphan</a>
.txt-orphan {
    display: block;
}

// @category Typography
// @name Standalone piece of text by mq
// @exampleextrasmall
//
// @markup
// <a class="txt-orphan-from-m ">.txt-orphan</a>
@each $breakpoint in $mq-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);
    @include mq($from: #{$breakpoint-name}) {
        .txt-orphan-from-#{$breakpoint-name} {
            display: block !important;
        }
    }
}

// @category Typography
// @name Overide font to non webfont
// @exampleextrasmall
//
// @markup
// <p class="txt-base">.txt-base</a>
.txt-base {
    font-family: $mainFontStack;
}

// @category Typography
// @name Uppercase text
// @exampleextrasmall
//
// @markup
// <a class="txt-uppercase">.txt-uppercase</a>
.txt-uppercase {
    text-transform: uppercase;
}

// @category Typography
// @name No wrap white space
// @exampleextrasmall
//
// @markup
// <a class="no-wrap">.no-wrap</a>
.no-wrap {
    white-space: nowrap;
}
