// @category Grid
// @name column simple grid
// @description Because our to level category length doesnt play ball! :P
// @info Only works from breakpoint m and up currently
//
// @markup
//     <div class="grid-container">
//         <ul class="grid-row">
//             <li class="grid-col7-m-1">grid-col7-1</li>
//             <li class="grid-col7-m-1">grid-col7-1</li>
//         </ul>
//     </div>

.grid-col7-1 {
    width: percentage((1 / 7));
}

@include mq($from: m) {
    .grid-col7-m-1 {
        width: percentage((1 / 7));
    }
}
