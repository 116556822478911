// Base imports and settings needed. Import this file into a style sheet then import any components you want after.
// All mixins are imported here plus some universal base styles
@import "vars";
@import "colours";

@import "mixins/bevel";
@import "mixins/btnHover";
@import "mixins/clearfixme";
@import "mixins/mq";
@import "mixins/gridInline";
@import "mixins/gridMixin";
@import "mixins/hardwareAcceleration";
@import "mixins/placeholder";
@import "mixins/spinner";
@import "mixins/translate3D";
@import "mixins/truncateMQ";
@import "mixins/whitespace";

@import "sass-functions/stripUnits";

@import "utils/normalize";
@import "layout/grid";
@import "layout/grid-inline";
@import "layout/grid-col7";
@import "layout/grid-fixed";
@import "utils/clearfixMq";
@import "utils/states";
@import "utils/float";
@import "utils/display";
@import "utils/border";
@import "utils/hide-fully";
@import "utils/hide-visually";
@import "utils/whitespace";
@import "utils/overflow";
@import "utils/layout";
@import "typography";
@import "tint-and-shade";


// Fix bounce of window on scroll within ios safari
html {
     overflow-x: hidden;
     -webkit-overflow-scrolling: touch;
    &.touchevents.device-mobile,
    &.touchevents.device-tablet {
        body {
            overflow: visible;
            -webkit-overflow-scrolling: touch;
            &.is-blocking {
                overflow-y: hidden;
            }
            &.is-modal-scroll-prevented {
                overflow-y: hidden;
                position:fixed;
            }
        }
    }
}

// Extend Normalize
// Fix iOS orientation change bug
html,
body {
    width: 100%;
    -webkit-text-size-adjust: 100%;
    @include mq($from: m) {
        height: 100%;
    }
}

menu {
    list-style-type: none;
}

ol {
    list-style: inside decimal;
}

img {
    max-width: 100%;
}

.letterbox {
    width: 100%;
    min-height: 100%;
    @include mq($from: m) {
        min-height: 100%;
        position: relative;
        padding-bottom: 704px;
    }

    @include mq($from: l) {
        padding-bottom: 683px;
    }

    .is-blocking & {
        overflow: hidden;
    }

    &.has-sticky-footer {
        // Added due to sticky footer
        margin-bottom: 50px;
        .ie & {
            margin-bottom: 0;
            padding-bottom: 50px;
        }
    }

    .no-touchevents .is-offset-left &,
    .no-touchevents .is-offset-right & {
        overflow-y: hidden;
    }
}

.is-cropped {
    overflow: hidden;
    max-height: 100vh;
    height: auto; /* iOS position:fixed; elements fix (not 100%) */
    min-height: 100%;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .is-cropped {
        height: 1024px;
    }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    .is-cropped {
        height: 768px;
    }
}

@media screen and (device-aspect-ratio: 40/71) {
    .is-cropped {
        height: 500px;
    }
}

//Remove mega-rounded lozenge corners on ipad
input,
textarea,
input[type="search"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -webkit-user-select: auto !important;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

input[type="radio"] {
    -webkit-appearance: radio;
}

////Overide for adsense to hide a flag
//#adsense-flag {
//    display: none;
//}

.gesture-detected * {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}
//TODO Hide on gesture class?
.gesture-detected .hide-on-gesture {
    display: none;
}

// Lists with inside bullets aligned with margin
.list-aligned-bullets {
    list-style:disc;
    margin-left:20px;
}
