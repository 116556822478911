// @category Sass Mixins
// @name btnHover
// @description Mixin that creates a hover animation for buttons
// @noexample
// @markup
// @include btnHover($colour, $pseudo: "before");

@mixin btnHover($colour, $pseudo: "", $textColour: "") {
    // TODO: need polishing with consideration of using only flat colors as well + shortcuts on transitions

    @if $pseudo != "" {
        &#{$pseudo} {
            transition: background-color 222ms ease-out;
        }
    } @else {
         transition: background-color 222ms ease-out;
    }

    .no-touchevents &:hover#{$pseudo},
    &:focus#{$pseudo} {
        background-color: $colour;
        @if $textColour != "" {
            color: $textColour;
        }
        text-decoration: none;
        // force change of cursor
        cursor: pointer;

        img,
        [class*="icn-"] {
            opacity: 0.95;
        }
    }
}
