.btn-comparison {
    @extend %btn;
    color: $white;
    background: $blade_70pc;
    border: 1px solid $blade_70pc;
    cursor: pointer;
    height: 30px;
    line-height: 26px;

    &.selected {
        border: 1px solid $agnosticGreen;
        background: $agnosticGreen;
        color: $white;
    }
}
