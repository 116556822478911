// Paypal button
//
// The one off use case for a checkout button
//
// Markup:
// <button class="btn-paypal {$modifiers}">Button (button.btn-secondary)</button>
// <a href="#" class="btn-paypal {$modifiers}">Button (a.btn-secondary)</a>
//
// :hover   - Highlight the button when hovered
// :focus   - Focused state
// :active  - Active state
// .is-active - Class based active state
//
// Styleguide 4.2

.btn-paypal {
    @extend %btn;
    color:$white;
    background-color:$paypalPalBlueLight;
}
.btn-paypal:hover {
    color: $white;
    background-color:$paypalPalBlueDark;;
}

// Specific for paypal checkout
#braintree-paypal-loggedin {
    max-width: none!important;
    background-color:$white;
    border: 1px solid $bark_20pc;
}

// Specific for paypal checkout
#bt-pp-cancel {
    display:none;
}