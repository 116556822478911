// @category Buttons
// @name Button Link
// @description Button without background styling, normally used with icons
//
// @markup
// <button class="btn-link"><span class="icon-clear"></span></button>

.btn-link {
    border: none;
    background: none;
    color: $colourLink;
    padding: 0;
    font-family: inherit;
    &:hover {
        text-decoration: underline;
    }
    &:disabled {
        text-decoration: none;
        color: $bark_10pc;
    }
}

