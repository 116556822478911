// @category Form
// @name Password Input
// @description detects the strength of the password.
// Levels:
//  - VERY WEAK: password shorter than min attribute value
//  - WEAK: char \\ digit \\ specialchar
//  - OK: char + (digit \\ specialchar)
//  - STRONG: char lowerCase + char upperCase + (digit || specialchar)
// @warning min attribute represents min length of the password, this attr is not required.
// @markup
// <form class="form" name="input-password">
//  <span class="password-input-wrapper">
//      <input type="password" id="form.password" name="form.password"
//          min="6"
//          class="password-input"
//          aria-required="true" required="required"
//          data-validation-rules="isValidPassword">
//  </span>
// </form>

@mixin strengthCircles($clr1, $clr2, $clr3, $clr4) {
    content: '';
    background: radial-gradient(circle, $clr1 25%, transparent 30%) -4px 5px,
    radial-gradient(circle, $clr2 25%, transparent 30%) 6px 5px,
    radial-gradient(circle, $clr3 25%, transparent 30%) 16px 5px,
    radial-gradient(circle, $clr4 25%, transparent 30%) 26px 5px;
    background-size: 20px 10px;
    background-repeat: no-repeat;
}

.password-input-wrapper {
    position: relative;
    display: block;
}

.password-input-wrapper:before {
    position: absolute;
    left: 50px;
    bottom: -24px;
}
.password-input-wrapper:after {
    position: absolute;
    left: 0;
    bottom: -24px;
    width: 50px;
    height: 20px;
    content: '';
}
.password-input-wrapper .btn-toggle-password {
    position: absolute;
    right: 12px;
    top: 13px;
    border-left: 1px solid $colourTextboxBorder;
    padding-left: $sSpacingPx;
    color: $defaultTextColour;
    width: 55px;
}

.password-input-wrapper {
    &.is-very-weak:before {
        content: 'Very weak';
    }
    &.is-very-weak:after {
        @include strengthCircles($successColour, $colourTxtQuaternary, $colourTxtQuaternary, $colourTxtQuaternary);
    }

    &.is-weak:before {
        content: 'Weak';
    }
    &.is-weak:after {
        @include strengthCircles($successColour, $successColour, $colourTxtQuaternary, $colourTxtQuaternary);
    }

    &.is-ok:before {
        content: 'Ok';
    }
    &.is-ok:after {
        @include strengthCircles($successColour, $successColour, $successColour, $colourTxtQuaternary);
    }

    &.is-strong:before {
        content: 'Strong';
    }
    &.is-strong:after {
        @include strengthCircles($successColour, $successColour, $successColour, $successColour);
    }
    & + .error-messages {
        margin-top: 20px;
    }
}

.is-very-weak .password-input {
    padding-right: 82px;
}

.is-weak .password-input {
    padding-right: 47px;
}

.is-ok .password-input {
    padding-right: 28px;
}

.is-strong .password-input {
    padding-right: 63px;
}
