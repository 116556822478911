// @category Sass Mixins
// @name Clearfix me
// @description Mixin which returns different types of clearfix
// @noexample
// @warning The internal mixins need to be named as such _. Spelling mistake in extend var name
// @markup
// @include clearfix-me($method: micro, $extemd: true);

// set default clearfix method to overflow
$clearfix-method: micro !default;
// can be one of: overflow, facebook, micro, stubborn

// create stub classes for each of the four clearfix methods

@mixin clearfix-overflow() {
    overflow: hidden;
}

@mixin clearfix-facebook() {
    display: table-cell;
    vertical-align: top;
    width: 10000px !important;
}

@mixin clearfix-micro() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin clearfix-stubborn() {
    display: table-cell;

    &:after {
        clear: both;
        display: block;
        visibility: hidden;
        overflow: hidden;
        height: 0 !important;
        line-height: 0;
        font-size: xx-large;
        content: "x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x";
    }
}

%clearfix-overflow {
    @include clearfix-overflow();
}

%clearfix-facebook {
    @include clearfix-facebook();
}

%clearfix-micro {
    @include clearfix-micro();
}

%clearfix-stubborn {
    @include clearfix-stubborn();
}

// extends so the output will be smaller

@mixin clearfix-me($method: $clearfix-method, $extemd: true) {
    @if $method != overflow and $method != facebook and $method != micro and $method != stubborn {
        @warn "The clearfix method #{$method} is unkown";
        @extend %clearfix-micro;
    } @else {
        @extend %clearfix-#{$method};
    }
}
