//
// USAGE NOTES
// ---------------
// ADDING A NEW COLOUR TO GUMTREE.COM
// 1. Please check to see if your colour already exists in the site - see below :)
//
// 2. Generate a colour name here: http://www.colblindor.com/color-name-hue/.
// Check colour name is not already taken [Note: may suggest a duplication or close duplication of colour].
// If colour is taken, generate a related name e.g. $apple > $grannySmith > $goldenDelicious etc.
//
// 3. Add use-case for your colour in vars.scss
//
// 4. Only use the new variable in vars in your component styling, never the raw colour
//
//

// Final New colour palette
// Brand
$leaf: #72ef36;
$leaf_dark: #39781b;
$berry: #e75462;
$berry_dark: #b54343;
$blade: #0d475c;
$myrtle: #f0ece6;
$myrtle_dark: #787673;

$bark: #3c3241;
$bark_dark: #1e1921;
$bark_80pc: #635b67;
$bark_70pc: #766f7a;
$bark_60pc: #8a848d;
$bark_40pc: #b1adb3;
$bark_20pc: #d8d6d9;
$bark_10pc: #f1f0f1;

$branch_dark: #262226;
$branch_60pc: #938f93;
$branch_40pc: #b7b4b7;
$branch_20pc: #dbdadb;
$branch_10pc: #f1f1f1;
$branch_5pc: #f6f5f6;

$blade_70pc: #557e8d;
$blade_10pc: #e6ecee;

$green_10pc: #eef8ed;
$red_10pc: #fdecea;

$gtall_26629_green: #498124;
$gtall_26629_green_dark: #527435;
$gtp_2992_green: #5ce00b;
$gtp_2992_green_dark: #54cc0a;
$gtp_2992_navy: #0d475c;
$gtp_2992_light_blue: #e7edef;
$gtp_2992_grey: #ecedef;
$gtp_2992_dark_grey: #636e77;

// Jobs
$maple: #f16f3c;
$maple_dark: #b83f03;
// Jobs

// Agnostic colours
$agnosticBlue: #007fb0;
$agnosticBlueLight: #99ccdf;
$agnosticRed: #e52815;
$agnosticOrange: #ff9900;
$agnosticYellow: #efc636;
$agnosticGreen: #52a744;
$agnosticGreenLight: #f0f8ee;
$agnosticGrey: #fafafa;
$white: white;
$black: black;

$agnosticGreen_10pc: #eef8ed;

// Third party colours Corporate Colours
$paypalPalBlueLight: #0079c1;
$paypalPalBlueDark: #00457c;
$whatsappLightGreen: #25d366;
$messengerBlue: #0084ff;
$twitterLightBlue: #38a8e0;
$pinterest: #c91618;
$facebook: #4267b2;
$facebookDark: #314e87;
$vrnTextboxColour: #fdec30;

// Black colours with alpha
// Deprecated
$black_5pc: rgba(0, 0, 0, 0.05);
$black_15pc: rgba(0, 0, 0, 0.15);
$black_20pc: rgba(0, 0, 0, 0.2);
$black_25pc: rgba(0, 0, 0, 0.25);
$black_30pc: rgba(0, 0, 0, 0.3);
$black_40pc: rgba(0, 0, 0, 0.4);
$black_75pc: rgba(0, 0, 0, 0.75);
// White
$white_50pc: rgba(255, 255, 255, 0.5);
$white_90pc: rgba(255, 255, 255, 0.9);
