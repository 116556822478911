// @category Buttons
// @name Button social login buttons
// @description used for creating specifically main buttons
// @exampleextrasmall
//
// @markup
// <button class="btn-icn-left btn-secondary btn-full-width btn-google-icon">Sign in with Google</button>

.btn-google-icon {
    background: url(../../../images/svg-sprite/google-logo.svg) no-repeat 12px 8px;
    background-size: 18px;
    background-position: 12px center;
    padding-left: 12px;
}

.btn-facebook-icon {
    background: $facebook;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid $facebook;
    padding-left: 12px;

    &:before {
        content: '';
        background: url(../../../images/responsive/orphans/f_logo.png) no-repeat;
        height: 24px;
        width: 24px;
        background-size: contain;
    }

    &:hover {
        background: $facebookDark;
    }
}
