// @category Horizontal Rule
// @name Horizontal Rule for text
// @description Adds a border behind text covering up line.
//
// @markup
// <span class="horizontal-rule"><span class="horizontal-rule-text">Or</span></span>

.horizontal-rule {
    margin: $mSpacingPx 0;
    border:0;
    background-color:transparent;
    border-top: 1px solid $horizontalRuleBorderColour;
    position: relative;
    width: 100%;
    display: block;
}

.horizontal-rule-text {
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -0.75em;
    background: $horizontalRuleTextBgColour;
    padding: 0 $baseLineHeightPx;
    &.secondary {
        background: $colourBoxBgSecondary;
    }
}