@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/framework/css/framework';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/framework/css/mixins/fontFace';

@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/gumicon/Gumicon';

@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/icons/icons';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/buttons/buttons';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/header/header';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/radio-toggle/skin/tabs';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/form/form';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/form/textbox/textbox';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/form/password-input/password-input';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/form/clear-input/clear-input';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/horizontal-rule/horizontal-rule';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/captcha/captcha';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/box/box';
@import '../../../../../mobile-web-static-assets/src/main/resources/rwd/components/footer/footer';
@import './components/link-button';
@import './components/input';
@import './components/footer';

// loads google font inter
@import '../../../../../mobile-web-static-assets/src/main/assets/rwd/font/inter/inter-google-font.scss';
@import '../../../../../mobile-web-static-assets/src/main/assets/rwd/font/montserrat/montserrat-google-font.scss';

body {
	padding: 0;
	margin: 0;
	font-size: 15px;
	line-height: 1.4;
	background-color: $syiPageBackgroundColour;
	min-height: 100vh;
}
