// @category Box
// @name Box Pullout
// @description A darker version of box
// @warning duplicate?
// @warning Relys to much on utility classes, needs to be more self reliant.
// @warning Lots of refactoring needed in box.
// @warning Box title is not compatible with box padding, needs work, not really used also.
// @warning box padding should probably be changed from a state to a child class and called box-content and surround things below title
// @order 3
//
// @markup
// <div class="box-pullout [state]">
//      <div class="box-title">Some content</div>
//      <div class="box-padding">Content of the box</div>
//      <img src="http://lorempixel.com/52/52/cats/" class="box-image" />
// </div>

.box-pullout {
    @extend %box;
    background-color: $boxPulloutColour;
}
