// @category Grid
// @name Fixed 20
// @description grid fixed width styling to make something 20% the width of it's parent element
// @warning Need to audit our grids as we are now running 3 side by side
//
// @Markup
// <div class="grid-fixed-20">grid-fixed-20</div>
//
.grid-col-fixed-20 {
    width: 20%;
}
