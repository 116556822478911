@import "../focusGlow";

// Base textbox
%textbox {
    display: inline-block;
    height: 44px;
    line-height: 20px;
    padding: 5px 8px;
    border: 1px solid $colourTextboxBorder;
    width: 100%;
    border-radius: 2px;

    font-family: inherit;
    font-size: $focusElementFontSize;

    &:focus {
        outline: none;
        border: 1px solid $textboxFocusBorderColour;
    }

    &:disabled {
        border: 1px solid $colourTextboxDisabledBorder;
        background: $colourTextboxDisabledBackground;
    }

    &::-ms-clear {
        display: none;
    }
    &::-webkit-inner-spin-button {
        display: none;
    }

    &.is-invalid {
        border: 1px solid $colourTextboxInvalidBorder;

        &:focus {
            @include textboxInvalidFocusGlow;
        }
    }
}

// @category Form
// @name Textbox
// @description Standard styling for input textboxes
// @state .is-invalid - Invalid validation state for text area
//
// @markup
// <input type="text" value="" />
// <div class="input-enhanced"><input type="text" value="no boarder" /></div>

//TODO : If more types of textbox exist we should move this to skin dir
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"] {
    @extend %textbox;
    .input-enhanced & {
        border: 1px solid transparent;
        &:focus {
            border: 1px solid $textboxFocusBorderColour;
        }
        &:disabled {
            border: 1px solid $colourTextboxDisabledBorder;
            background: $colourTextboxDisabledBackground;
        }
    }
}

input[type="search"] {
    box-sizing: border-box;
}

input[type="password"] {
    -webkit-text-security: disc;
}

// @category Form
// @subcategory Textbox
// @name Textbox Prefix
// @description Base prefix content for textbox

%textbox-prefix {

    position: relative;

    &:before {
        position: absolute;
        top: 11px;
        left: 10px;
        content: "";
        z-index: 2;
        pointer-events: none;
    }

    input {
        position: relative;
        z-index: 1;
        padding-left: 22px;
    }
}


// @category Form
// @name Textbox Prefix Pound
// @description Add prefix pound to a textbox
//
// @markup
// <div class="textbox-prefix-pound"><input type="text" value="" /></div>
.textbox-prefix-pound {

    @extend %textbox-prefix;

    &:before {
        content: "£";
    }
}
