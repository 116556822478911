// @category Box
// @name Box link
// @description A box thats really a button???
// @warning Deprecated, not really a component, maybe a button
//
// @markup
//<div class="box box-link">
//    Text for the link
//    <span class="open-closed-icons set-right">
//        <span class="icn-plus txt-highlight closed-icon"></span>
//        <span class="icn-minus txt-highlight open-icon"></span>
//    </span>
//</div>

.box-link {
    &, &:visited {
        color: $colourBoxLink;
    }
    font-weight: $fontBoldWeight;
    cursor: pointer;
    padding: 0 30px 0 12px;
    line-height: 35px;
    .open-closed-icons {
        position: absolute;
        right: 10px;
        top: 0;
    }
    .no-touchevents &:hover {
        text-decoration: none;
    }
}