/*! normalize.css 2012-03-11T12:53 UTC - http://github.com/necolas/normalize.css */

/* Has been modified from original, typography has been moved out, and a few things have been added to bottom*/

/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */

audio,
canvas,
video {
    display: inline-block;
    //*display: inline; -- Removing since we dont support < ie8
    //*zoom: 1; -- Removing since we dont support < ie8
}

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/* =============================================================================
   Base
   ========================================================================== */

/*
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */

/*
 * Addresses margins handled incorrectly in IE6/7
 */

body {
    margin: 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */

abbr[title] {
    border-bottom: 1px dotted;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/

b,
strong {
    font-weight: $fontBoldWeight;
}

blockquote {
    margin: 16px 40px;
}

/*
 * Addresses styling not present in S5, Chrome
 */

dfn {
    font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */

mark {
    background: #ff0;
    color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */

p,
pre {
    margin: 0;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */

pre,
code,
kbd,
samp {
    font-family: monospace, serif;
    font-size: 16px;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */

/* 1 */

q {
    quotes: none;
}

/* 2 */

q:before,
q:after {
    content: '';
    content: none;
}

small {
    font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -3px;

}

sub {
    bottom: 4px;
}

/* =============================================================================
   Lists
   ========================================================================== */

/*
 * Addresses margins set differently in IE6/7
 */

menu,
ol,
ul,
dl,
dt,
dd {
    margin: 0;
}

menu,
ol,
ul,
dl,
dt,
dd {
    padding: 0;
}

/*
 * Corrects list images handled incorrectly in IE7
 */

ul,
ol,
dl,
dt,
dd {
    list-style: none;
    list-style-image: none;
}

/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/*
 * Corrects overflow displayed oddly in IE9
 */

svg:not(:root) {
    overflow: hidden;
}

/* =============================================================================
   Figures
   ========================================================================== */

/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */

figure {
    margin: 0;
}

/* =============================================================================
   Forms
   ========================================================================== */

/*
 * Corrects margin displayed oddly in IE6/7
 */

form {
    margin: 0;
}

/*
 * Define consistent border, margin, and padding
 */
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

/*
 * 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3
 * 3. Corrects alignment displayed oddly in IE6/7
 */
legend {
    border: 0; /* 1 */
    padding: 0;
}

/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    // *vertical-align: middle; /* 3 */  -- Removing since we dont support < ie8
}

/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */

button,
input {
    line-height: normal; /* 1 */
}

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */

button,
.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer; /* 1 */
    -webkit-appearance: button; /* 2 */
}

/*
 * Re-set default cursor for disabled elements
 */

button[disabled],
input[disabled] {
    cursor: default;
}

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
      Known issue: excess padding remains in IE6
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/* =============================================================================
   Tables
   ========================================================================== */
/*
 * Remove most spacing between table cells
 */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

// Custom

/* apply a natural box layout model to all elements */
*, *:before, *:after {
    box-sizing: border-box;
}

body {
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
}

html {
    -webkit-text-size-adjust: none;
}