// @category Buttons
// @name Header button
// @description This is the button used in the header
// @theme dark
// @exampleextrasmall
// @info Should this be part of header.scss instead
//
// @markup
// <button class="btn-header">Click me</button>
// <button class="btn-header :disabled">I'm disabled</button>
.btn-header {
    @extend %btn;
    background: inherit;
    border: 1px solid transparent;
    color: $white;
    min-width: 44px;
    padding: 0 $sSpacingPx;
    .btn-header-txt {
        display: none;
    }
    [class^="icn-"] {
        color: $white;
        margin-right: 0;
        transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .no-touchevents &:hover,
    &:focus {
        text-decoration: none;
        [class^="icn-"] {
            color: $headerNavSearchTxtHover;
            transform: rotate(30deg);
        }

    }

}
@include mq($from: s) {
    .btn-header {
        .btn-header-txt {
            display: inline;
        }

        .no-touchevents &:hover,
        &:focus {
            color: $headerBtnHoverColour;
            background-color: $headerBtnBackgroundHoverColour;
            [class^="icn-"] {
                color: $headerBtnHoverColour;
            }
        }
    }
}
