.g-recaptcha-container {
    display: flex;
    flex-direction: column-reverse;
    > div {
        align-self:flex-end;
        transform: scale(0.8);
        order: -1;
        transform-origin: 100% 100%;
        > div {box-shadow: #D8D6D9 0 0 5px !important;}
    }
}

.g-recaptcha {
    margin-bottom: $sSpacingPx;
}

.ie9 .g-recaptcha-container > div {
    transform: scale(1);
    margin-bottom: $sSpacingPx;
}
