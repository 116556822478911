// @category Box
// @name Standard Box
// @description A generic box class
// @state .box-secondary - Darker version of box
// @state .is-closed - Hides the box
// @warning Box image is a bit broken.
// @warning Relys to much on utility classes, needs to be more self reliant.
// @warning Lots of refactoring needed in box.
// @warning Box title is not compatible with box padding, needs work, not really used also.
// @warning box padding should probably be changed from a state to a child class and called box-content and surround things below title
// @order 1
//
// @markup
// <div class="box [state]">
//      <div class="box-title">Some content</div>
//      <div class="box-padding">Content of the box</div>
//      <img src="http://lorempixel.com/52/52/cats/" class="box-image" />
// </div>

// @category Box
// @name Standard Box with sections
// @description A generic box class
// @state .box-secondary - Darker version of box
// @state .is-closed - Hides the box
// @warning Relys to much on utility classes, needs to be more self reliant. Lots of refactoring needed in box. Box title is not compatible with box padding, needs work, not really used also. box padding should probably be changed from a state to a child class and called box-content and surround things below title
// @order 2
//
// @markup
// <div class="box [state]">
//      <div class="box-title">Some content</div>
//      <div class="box-section">Content of section 1</div>
//      <div class="box-section">Content of section 2</div>
// </div>

%box {
    position: relative;
    margin: 0 0 $mSpacingPx;
    padding: 0;
    border: 1px solid $colourBorder;
    background-color: $white;

    border-radius: 3px;
    @include clearfix-me();

    display: block;
    &.is-closed {
        display: none;
    }

    .box-image {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 25;
    }
}

.box {
    @extend %box;

    &.box-bg-light {
        background: $branch_10pc;
    }

    .box-section:last-of-type {
        border-bottom: none;
    }
}

.box-padding {
    padding: $grid-gutter-width;
}

.box-toggle {
    display: none;

    &.is-open {
        display: block;
        z-index: 2;
    }
}
.box-slide-toggle{
    opacity: 0;
    height: 0;
    visibility: hidden;
    &.is-open{
        opacity: 1;
        visibility: visible;
        height: auto;
        animation: slideInRight 0.1s linear 1;
    }
}

.is-affixed.box-section {
    background: $white;
    box-shadow: 0px 2px 9px #ccc;
}

.box-section {
    border-bottom: 1px solid $colourSectionBorder;
    padding: $grid-gutter-width;

    [data-trigger] [class^="icn-"], [data-trigger] [class*="icn-"] {
        color: $colourTxtQuaternary;
        margin-top: 4px;
    }

    .is-open {
        [class^="icn-"], [class*="icn-"] {
            transform: rotate(180deg);
        }
    }
    .is-closed {
        display: none;
    }
    .has-popup {
        cursor: pointer;
    }

}
.box-secondary {
    background-color: $colourBoxBgSecondary;
}

.box-title {
    border-bottom: 1px solid $colourSectionBorder;
    padding: 15px 18px;
    font-weight: $fontNormalWeight;
    border-radius: 3px 3px 0 0;
}

//Import class skins
@import "skins/box-pullout";
@import "skins/box-info";
@import "skins/box-link";
@import "skins/box-collapsible";

.no-js {
    .box-toggle {
        display: block;
    }
}
