// @category Buttons
// @name Indented button
// @description Button that indents on click and stacks an icon on top
// @exampleextrasmall
//
// @markup
// <button class="btn-indent"><span class="icn-tag"></span><span class="txt-orphan">Click me</span></button>
//
// @state :is-selected - When its selected.

.btn-indent {
    border-bottom: solid 1px $colourBorder;
    border-top: solid 1px $branch_20pc;
    color: $colourTxtSecondary;
    @extend %grid-component-container;
    cursor: pointer;
    display: block;
    text-align: center;

    &:hover {
        background-color: $agnosticGrey;
    }

    + li {
        border-left: solid 1px $colourBorder;
    }

}

.btn-indent.is-selected {
    background-color: $agnosticGrey;
}

.btn-indent-icn {
    padding: 0 5px;
}
.is-selected .btn-indent-icn {
    color: $colourTxtSelected;
}

.btn-indent-state {
    float: right;
    @extend %icon-font;
    @extend .icn-plus;

    color: $branch_60pc;
}

.is-selected .btn-indent-state {
    @extend .icn-minus;

    color: $branch_60pc;
}
