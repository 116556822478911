// @category Utils
// @name Display styles
// @description Set the display property on an element
// @warning heavy class, does it justify itself
//
// @markup
// <div class="inline-block">.inline-block</div>
// <div class="inline">.inline</div>
.inline-block {
    display: inline-block !important;
}

