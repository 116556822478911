// Sizes
$font-size--xsmall:         10px;
$font-size--smaller:        12px;
$font-size--small:          14px;
$font-size--base:           15px;
$font-size--medium:         16px;
$font-size--large:          18px;
$font-size--xlarge:         20px;
$font-size--xxlarge:        40px;

// Weights
$font-weight--normal:       330;
$font-weight--bold:         600;
$font-weight--bolder:       700;

// Components' font
$font-size--focus-element:  16px;
