@import "../../../../../../../frontend/src/common/ui-library/src/base/guttering.scss";

// @category Site chrome
// @name Footer
// @info Does not display perfectly in styleguide due to breakpoint mismatch, might need to look at improving this
//
// @markup
// [#assign pageOptions = {} /]
// [#assign gumtreehost = "" /]
// [#assign model = {"core" :{"myGumtreeHost" : "", "host": "", "currentYear": 2015}} /]
// [#include "/@inc/shared/freemarker/pageChrome/_footer.ftl" /]

// @category Site chrome
// @name Footer without social links
// @info Does not display perfectly in styleguide due to breakpoint mismatch, might need to look at improving this
//
// @markup
// [#assign pageOptions = {"noSocialPanel":true} /]
// [#assign model = {"core" :{"myGumtreeHost" : "", "host": "", "currentYear": 2015}} /]
// [#include "/@inc/shared/freemarker/pageChrome/_footer.ftl" /]

.footer-banner-container {
    @include mq($from: m) {
        position: absolute;
        width: 100%;
    }
}

.footer {
    border-top: 1px solid $footerBorderColour;
    background-color: $footerBackgroundColour;
    padding-top: $mSpacingPx;
    color: $footerTextPrimaryColour;

    .footer-primary-navigation {
        margin-bottom: 16px;

        @include mq($from:s) {
            margin-bottom: 0;
        }
    }

    .footer-links-list {
        &:first-of-type, &:last-of-type {
            @include mq($from: l) {
                @include make-column-offset(1);
            }
        }
    }
}

.footer-heading {
    font-size: 17px;
    color: $footerHeadingColor;
    margin-bottom: 8px;
    font-weight: $font-weight--bold;

    &.is-open .footer-icon {
        transform: rotate(180deg);
    }

    .footer-icon {
        float: right;
    }
}

.footer-icon:before {
    color: $white;
    font-size: 12px;
}

.footer-primary-links {
    transition: all 300ms linear;
    @include clearfix-me();
    margin-bottom: 20px;

    > li {
        > a {
            display: block;
            padding: 5px 0;
            color: $footerLinksColor;
        }
    }
}

.mobile-app-button {
    width: 170px;
}

// Copyright
.footer-copyright {
    color: $footerTextCopyWriteColour;
    text-align: center;
    font-size: $fontSizeSub;
    letter-spacing: 0.03em;
    margin-bottom: $xlSpacingPx;
    line-height: 20px;

    a {
        color: $footerLinksColor;
    }
}

@include mq($from: l) {
    .footer {
        padding-left: $pageGutter;
        padding-right: $pageGutter;


    }
}

.footer-hr {
    margin: 0 0 16px;
    background: $bark_80pc;
    height: 2px;
}

.footer-social {
    @include mq($from: m) {
        .footer-social-wrapper {
            display: grid;
            justify-content: start;
        }
    }
}

.footer-social-buttons {
    display: flex;
    justify-content: center;
    color: $socialMediaButtonsColour;

    @include mq($from: m) {
        justify-content: flex-start;
    }

    li {
        @include mq($from: m) {
            &:first-child {
                margin-left: -$gutter-size;
            }
        }
        a {
            display: inline-flex;
            padding: $gutter-size;
            margin-top: -$gutter-size;
        }
    }
}
