// @category Form
// @name Clear input
// @description When an input has text in it and it has focus the clear icon should be visible.
// @warning markup example is wrong as the code example is being processed by the js, need to fix
//
// @markup
// <form class="form" name="clearinput">
//      <div class="form-row">
//          <input type="text" name="clearme" value="clear me" />
//      </div>
//      <div class="form-row">
//          <input type="text" name="clearmemanual" data-clearinput="false" value="you can only clear me manually" />
//      </div>
// </form>

.clear-input-wrapper {
    position: relative;
    display: block;
}

.clear-input {
    position: absolute;
    top: 10px;
    right: 8px;
    left: auto;
    cursor: pointer;
    display: none;
    z-index: 500;
}

.clear-input-wrapper.has-clear {
    .no-touchevents &:hover,
    &.is-focussed {
        .clear-input.is-visible {
            display: block;
        }
        > input {
            @include mq($from: m) {
                padding-right: 30px;
            }
        }
    }
}
