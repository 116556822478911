// @category Buttons
// @name Main primary button
// @description The main primary button
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
// @theme dark
// @order 3
//
// @markup
// <button class="btn-main-primary">Click me</button>

.btn-main-primary {
    @extend %btn;
    color: $colourBtnTextMainPrimary;
    background: $colourBtnBackgroundMainPrimary;
    @include btnHover($colourBtnBackgroundMainPrimaryHover);
    border: 1px solid $colourBtnBorderMainPrimary;
}
