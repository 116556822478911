// @category Buttons
// @name Secondary button
// @description The secondary button
// @exampleextrasmall
//
// @markup
// <button class="btn-secondary">Click me</button>
// <button class="btn-secondary :disabled">I'm disabled</button>

//Car Loans for you specific brand colours
$carLoanLightBlue: #272b7b;
$carLoanDarkBlue: #000033;
$carLoanDarkBlueHover: #00002f;

//Money advice service specific brand colours
$moneyAdviceLightGreen: #79ca30;
$moneyAdviceDarkGreen: #5f9624;
$moneyAdviceDarkGreenHover: #598d21;

//Experian service specific brand colours
$experianLightBlue: #015cad;
$experianLightRed: #ed174c;
$experianDarkRedHover: #cd174c;
$experianDarkBlue: $carLoanDarkBlue;
$experianDarkBlueHover: $carLoanDarkBlueHover;

//Base Colours
$gorse: #fdec30;

.experian-text {
    color: $experianLightBlue;
}

.btn-partnership {
    @extend %btn;
    color: $white;
    background: #0CA1EB;
    border: none;
    overflow: hidden;
    @include btnHover($branch_60pc);

    // This adds an .is-open state to the primary button
    &.is-open, .no-touchevents &.is-open:hover,
    &:focus {
        @extend %btn-is-open;
    }

    //disabled state of the button
    &.is-disabled {
        pointer-events: none;
        color: $white;
    }
}

.btn-partnership-money-advice {
    @extend %btn;
    color: $white;
    background: $moneyAdviceLightGreen;
    border: 1px solid $moneyAdviceLightGreen;
    @include btnHover($moneyAdviceDarkGreenHover);

    // This adds an .is-open state to the primary button
    &.is-open, .no-touchevents &.is-open:hover {
        @extend %btn-is-open;
    }

    //disabled state of the button
    &.is-disabled {
        pointer-events: none;
        color: $white;
    }
}

.btn-partnership-experian{
    @extend %btn;
    color: $white;
    background: $experianLightRed;
    border: 1px solid $experianLightRed;
    @include btnHover($experianDarkRedHover);

    // This adds an .is-open state to the primary button
    &.is-open, .no-touchevents &.is-open:hover {
        @extend %btn-is-open;
    }

    //disabled state of the button
    &.is-disabled {
        pointer-events: none;
        color: $white;
    }
}

