// @category Form
// @name Form layout classes
// @warning Needs work to get it into stylguide
//
// @markup
// <h2 class="h-underline-m">Email</h2>
// <div class="grid-col-right txt-right form-required">* Required</div>

.form-row {
    @include clearfix-me();
    margin-bottom: $sSpacingPx;
}

.form-row-inline {
    @extend .form-row;
}

.form-row-inline > li {
    width: 50%;
    float: left;
    list-style: none;
}

.form-inline {
    display: inline;
}

// kept for back compatibility, components using this class
// should be using form-txt-valign instead
.form-row-label {
    line-height: 35px;
}

.form-txt-valign {
    padding-top: 6px;
    padding-bottom: 6px;
}

.form-label {
    margin-bottom: 6px;
    font-size: 15px;
    font-weight: $fontBoldWeight;
}

.form label {
    display: block;
    color: $colourLabel;

    small {
        color: $colourLabelSmall;
    }
}

.form .form-label-inline {
    display: inline-block;
}

.error-messages {
    color: $importantColour;
    margin-top: 5px;
}

.no-js .form-response-panel {
    display: none;
}
.form-error {
    display: none;
}
.form {
    &.is-invalid-form {
        .form-error {
            display: block;
        }
    }
}

.reply-form-message {
    padding: 10px 13px;
}

.form.is-success, .form.is-error {
    .form-panel {
        display: none;
    }
    .form-response-panel {
        display: block;
    }
}

.form.is-success {
    .form-success-panel {
        display: block;
    }
    .form-error-panel {
        display: none;
    }
}

.form.is-error {
    .form-success-panel {
        display: none;
    }
    .form-error-panel {
        display: block;
    }
}
.form-required {
    margin-top: -47px;
}

.form-input-with-button {
    position: relative;
    [name="postcode"] {
        padding-right: 50px;
    }
    .btn-right {
        position: absolute;
        right: 0;
        top: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.form-toggle {
    display: none;
}

.form-toggle-active {
    visibility: hidden;
    position: absolute;
    left: -999em;
}

.form-toggle:checked + .form-toggle-states > .form-toggle-active {
    visibility: visible;
    position: relative;
    left: 0;
}

.form-toggle:checked + .form-toggle-states > .form-toggle-inactive {
    visibility: hidden;
    position: absolute;
    left: -999em;
}

