$whitespace-keys: (p padding, m margin, a '', v -top -bottom, h -left -right, t -top, r -right, b -bottom, l -left);

@function expand-whitespace-key($k) {
    @each $pair in $whitespace-keys {
        $short: nth($pair, 1);
        $long: nth($pair, 2);
        // v and h need to pass in 2 parameters
        @if (length($pair) == 3) {
            $long: (nth($pair, 2) nth($pair, 3));
        }
        @if $short == $k {
            @return $long;
        }
    }
    @warn "Couldn't expand #{$k}";
    @return $k;
}

@mixin whitespace($units: (), $sizes: 6) {
    $tShirtSizes: (n, xs, s, m, l, xl, xxl);
    $types: (p, m);
    $locations: (a, v, h, t, r, b, l);
    $fontHeight: ($baseFontSize/1px) * $baseLineHeight; //24

    //if user didn't define their own spacing units
    @if length($units) == 0 {
        @for $i from 1 through $sizes {
            $unit: (round($fontHeight/$i))+px;
            //calculate unit base on fraction of font height
            $units: join($unit, $units);
        }
    }
    $units: join(0, $units);

    @for $i from 1 through length($units) {

        $tShirtSize: nth($tShirtSizes, $i);

        @each $location in $locations {
            @each $type in $types {
                .space-#{$type}#{$location}#{$tShirtSize} {
                    @each $whitespace-location in expand-whitespace-key($location) {
                        $n: strip-units(nth($units, $i));
                        @if $n == 0 {
                            #{expand-whitespace-key($type)}#{$whitespace-location}: $n !important;
                        } @else {
                            #{expand-whitespace-key($type)}#{$whitespace-location}: $n+px !important;
                        }
                    }
                }
            }
        }
    }
}

@mixin negative-whitespace($units: (), $sizes: 6) {
    $tShirtSizes: (n, xs, s, m, l, xl, xxl);
    $types: (m);
    $locations: (a, v, h, t, r, b, l);
    $fontHeight: ($baseFontSize/1px) * $baseLineHeight; //24

    //if user didn't define their own spacing units
    @if length($units) == 0 {
        @for $i from 1 through $sizes {
            $unit: (round($fontHeight/$i))+px;
            //calculate unit base on fraction of font height
            $units: join($unit, $units);
        }
    }
    $units: join(0, $units);

    @for $i from 1 through length($units) {

        $tShirtSize: nth($tShirtSizes, $i);

        @each $location in $locations {
            @each $type in $types {
                .neg-space-#{$location}#{$tShirtSize} {
                    @each $whitespace-location in expand-whitespace-key($location) {
                        $n: strip-units(nth($units, $i));
                        @if $n == 0 {
                            #{expand-whitespace-key($type)}#{$whitespace-location}: $n !important;
                        } @else {
                            #{expand-whitespace-key($type)}#{$whitespace-location}: $n+px !important;
                        }
                    }
                }
            }
        }
    }
}
