// @category Buttons
// @name Main quaternary button
// @description The main quaternary button
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
// @theme dark
// @order 3
//
// @markup
// <button class="btn-quaternary">Click me</button>

.btn-quaternary {
    @extend %btn;
    color: $footerBackgroundColour;
    background: $colourBtnTextMainPrimary;
    @include btnHover($colour: $colourBtnTextNavigation, $textColour: $colourBtnTextMainPrimary);
    border: 1px solid $colourBtnTextNavigation;
    &.selected, &:hover {
        background: $colourBtnTextNavigation;
        color: $colourBtnTextMainPrimary;
    }
}
