// @category Utils
// @name Floats
// @description Float elements to the left or right
//
// @markup
// <div class="set-left">.set-left</div>
// <div class="set-right">.set-right</div>

.set-left {
    float: left;
}

.set-right {
    float: right;
}

.set-right-wrap-small {
	@include mq($from: xs, $to: s) {
		float: none;
	}
}

.set-extreme-right {
	position: absolute;
	top: 8px;
    right: 4px;
}