// Icons
.bitmap-icons {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.gumsheild-icon {
    background-image: url("../../../images/orphans/gumshield.png");
    background-image: url("../../../images/svg/gumshield.svg"), none;
    width: 52px;
    height: 52px;
}

.gumtree-logo-svg,
.gumtree-text-svg,
.gumtree-pro-text-svg {
    float: left;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
}

.gumtree-text-svg,
.gumtree-pro-text-svg {
    margin-left: 6px;
    background-size: 100% auto;
}

.gumtree-logo-svg {
    background-image: url("../../../images/svg/gumtree_logo.svg");
    width: 29px;
    background-size: auto 100%;
}

.gumtree-text-svg {
    background-image: url("../../../images/svg/gumtree_logo_text.svg");
}

.gumtree-pro-text-svg {
    background-image: url("../../../images/svg/gumtree_for_business.svg");
}

.hpi-check-logo {
    background-image: url("../../../images/orphans/hpi/logo-2x.png");
    background-repeat: no-repeat;
    background-size: 81px 21px;
    width: 81px;
    height: 21px;
}

.icn-icons-flag-uk {
    background-image: url("../../../images/orphans/flag-uk-2x.png");
    background-image: url("../../../images/svg/Flag_of_England.svg"), none;
    background-repeat: no-repeat;
    background-size: 56px 37px;
    width: 56px;
    height: 37px;
}

.icn-icons-flag-scotland {
    background-image: url("../../../images/orphans/flag-scotland-2x.png");
    background-repeat: no-repeat;
    background-size: 56px 37px;
    width: 56px;
    height: 37px;
}

.icn-icons-flag-wales {
    background-image: url("../../../images/orphans/flag-wales-2x.png");
    background-repeat: no-repeat;
    background-size: 56px 37px;
    width: 56px;
    height: 37px;
}

.icn-icons-flag-ni {
    background-image: url("../../../images/orphans/flag-ni-2x.png");
    background-repeat: no-repeat;
    background-size: 56px 37px;
    width: 56px;
    height: 37px;
}

.icn-icons-visa {
    background-image: url("../../../images/orphans/visa.svg");
    background-repeat: no-repeat;
    background-size: 37px 24px;
    width: 37px;
    height: 24px;
}

.icn-icons-card {
    background-repeat: no-repeat;
    background-size: 38px 24px;
    width: 38px;
    height: 24px;
    top: 0px !important;
}

.icn-icons-full-fill-star {
    background-image: url("../../../images/svg/star-full.svg");
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 2px;
}

.icn-icons-half-fill-star {
    background-image: url("../../../images/svg/star-half.svg");
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 2px;
}

.icn-icons-empty-star {
    background-image: url("../../../images/svg/star-empty.svg");
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 2px;
}

.icn-icons-delta {
    background-image: url("../../../images/orphans/delta-2x.png");
}

.icn-icons-electron {
    background-image: url("../../../images/orphans/electron-2x.png");
}

.icn-icons-mastercard-small {
    background-image: url("../../../images/orphans/mastercard.svg");
}

.icn-icons-maestro-small {
    background-image: url("../../../images/orphans/maestrocard.svg");
}

.icn-icons-amex {
    background-image: url("../../../images/orphans/amex.svg");
}

.icn-icons-tick {
    background-image: url("../../../images/svg/tick.svg");
    background-size: 26px;
    height: 26px;
    width: 26px;
}

.icn-icons-paypal {
    background-image: url("../../../images/orphans/paypal-2x.png");
    background-repeat: no-repeat;
    background-size: 62px 17px;
    width: 62px;
    height: 17px;
}

.icn-icons-verified-master {
    background-image: url("../../../images/orphans/verified-master-2x.png");
    background-repeat: no-repeat;
    background-size: 122px 59px;
    width: 122px;
    height: 59px;
}

.icn-icons-verified-visa {
    background-image: url("../../../images/orphans/verified-visa-2x.png");
    background-repeat: no-repeat;
    background-size: 122px 59px;
    width: 122px;
    height: 59px;
}

.icn-icons-paypal_white {
    background-image: url("../../../images/orphans/paypal_white-2x.png");
    background-repeat: no-repeat;
    background-size: 86px 21px;
    width: 86px;
    height: 21px;
}

.icn-icons-paypal_white-no-logo {
    background-image: url("../../../images/orphans/paypal_white-no-logo.png");
    background-repeat: no-repeat;
    background-size: 62px 17px;
    width: 62px;
    height: 17px;
}

.icn-icons-popular {
    background-image: url("../../../images/orphans/popular-2x.png");
    background-repeat: no-repeat;
    background-size: 81px 84px;
    width: 81px;
    height: 84px;
}

.icn-icons-info {
    background-image: url("../../../images/svg/info_circle_outline.svg");
    background-size: 24px;
    height: 24px;
    width: 24px;
}
