// @category Box
// @name Collapsible Box
// @description Box that expands and collapses with clicking the title
//
// @markup
//<div class="box-collapsible">
//    <div class="box-collapsible-title space-mbn space-mts" data-broadcast="channel:trigger.box-collapsible">
//      Title text
//      <span class="box-collapsible-right">
//            <span class="icn-plus" data-toggler="channel:manage.deactivate,className:[icon-plus,icon-minus],selfBroadcast:false"></span>
//      </span>
//   </div>
//   <div class="box-collapsible-content" data-toggler="channel:trigger.box-collapsible,className:hide-fully,selfBroadcast:false">
//   </div>
//</div>

.box-collapsible + .box-collapsible {
    margin-top: $sSpacingPx;
}
.box-collapsible-title {
    font-weight: $fontBoldWeight;
    cursor: pointer;
    padding: 0 30px 0 12px;
    line-height: 35px;
    position: relative;
    border: 1px solid $colourBorder;
    background-color: $white;
    display: block;
    color: $defaultTextColour;
}
.box-collapsible-right {
    float: right;
    position: absolute;
    right: 10px;
    top: 0;
}

.box-collapsible-content {
    padding: 12px;
    background: $white;
    border-left: 1px solid $colourBorder;
    border-right: 1px solid $colourBorder;
    border-bottom: 1px solid $colourBorder;
    position: relative;
}
