// @category Buttons
// @name Secondary button
// @description The secondary button
// @order 2
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
//
// @markup
// <button class="btn-secondary">Click me</button>

.btn-secondary {
    @extend %btn;
    background: $colourBtnBackgroundSecondaryGtp2992Main;
    color: $colourBtnBackgroundSecondaryGtp2992Navy;
    border: 1px solid $colourBtnBackgroundSecondaryGtp2992Navy;
    @include btnHover(
        $colourBtnBackgroundSecondaryGtp2992Dark,
        "",
        $colourBtnBackgroundSecondaryGtp2992Navy
    );

    // This adds an .is-open state to the primary button
    &.is-open,
    .no-touchevents &.is-open:hover,
    .no-touchevents &.is-open:focus {
        @extend %btn-is-open;
    }

    &.is-waiting:not(.is-disabled),
    &.is-waiting:hover {
        background: $colourBtnBackgroundSecondaryHover;
    }
}
