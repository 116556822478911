// @category Utils
// @name Hide fully
//
// @description Hide from everyone including screen readers
//
// @markup
//<p class="hide-fully">Hidden</p>

.hide-fully {
    display: none !important;
    visibility: hidden;
}

// @category Utils
// @name Hide fully with no JS
//
// @description Hide from everyone when there is no js
//
// @markup
//<p class="hide-fully-no-js">Hidden</p>

.no-js .hide-fully-no-js {
    display: none !important;
    visibility: hidden;
}

// @category Utils
// @name Hide fully per breakpoint
//
// @description Hide from everyone including screen readers only on desktop based on each of our breakpoints (xs, s, m, l, xl)
//
// @markup
// <p class="hide-fully-to-s">Hidden to s breakpoint</p>
// <p class="hide-fully-from-s">Hidden from s breakpoint</p>
// <p class="hide-fully-to-l">Hidden to l breakpoint</p>
// <p class="hide-fully-from-l">Hidden from l breakpoint</p>
// <p class="hide-fully-l">Hidden only to l breakpoint</p>

@for $i from 1 through length($mq-breakpoints) {
    $breakpoint: nth($mq-breakpoints, $i);

    $breakpoint-name: nth($breakpoint, 1);

    // .hide-fully-xs and .hide-fully-xxl make no sense - they are really
    // .hide-fully-to-xs and .hide-fully-from-xxl
    @if $i > 1 and $i < length($mq-breakpoints) {
        $next-breakpoint: nth($mq-breakpoints, $i+1);
        $next-breakpoint-name: nth($next-breakpoint, 1);

        @include mq($from: #{$breakpoint-name}, $to: #{$next-breakpoint-name}) {
            .hide-fully-#{$breakpoint-name} {
                display: none !important;
                visibility: hidden;
            }
        }
    }

    @include mq($to: #{$breakpoint-name}) {
        .hide-fully-to-#{$breakpoint-name} {
            display: none !important;
            visibility: hidden;
        }
    }

    @include mq($from: #{$breakpoint-name}) {
        .hide-fully-from-#{$breakpoint-name} {
            display: none !important;
            visibility: hidden;
        }
    }
}

// @category Utils
// @name Hide fully javascript
//
// @description Hide from everyone including screen readers only when js is enabled (via modernizr js class)
//
// @Markup
// <p class="hide-fully-js">Hidden to s breakpoint</p>

.hide-fully-js {
    .js & {
        display: none !important;
        visibility: hidden;
    }
}

// TODO : Support for ie8 with maximum size hide so only correct elements are shown
