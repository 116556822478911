// @category Tabs
// @name Tabs
// @warning To many utility classes for no reason.
// @warning Naming is bad.
// @warning Stored with js that is not necessarily always related.
// @TODO This should definately be a macro rather than a markup example.
// @TODO Maybe tabs should not be a grid things, but a display table thing with max-widths
//
// @markup
// <div class="tabs-triggers">
//    <ul>
//        <li>
//            <a class="is-open" href="#" data-radiotoggle="channel:top-links,primary:tab1,selfBroadcast:true" aria-haspopup="true" aria-controls="tab1">
//              Tab 1
//            </a>
//        </li>
//        <li>
//            <a class="" href="#" data-radiotoggle="channel:top-links,primary:tab2,selfBroadcast:true" aria-haspopup="true" aria-controls="tab2">
//              Tab 2
//            </a>
//        </li>
//    </ul>
// </div>
// <div class="tabs-content space-phs space-pts">
//    <div id="tab1" class="radio-toggle-panel is-open" data-radiotoggle="channel:top-links,primary:tab1" aria-expanded="true">
//        Tab 1 content
//    </div>
//    <div id="tab2" class="radio-toggle-panel" data-radiotoggle="channel:top-links,primary:tab2" aria-expanded="false">
//        Tab 2 content
//    </div>
// </div>

.AB_GTP-2807B {
    .my-account-tabs.tabs-triggers {
        &.space-mts {
            margin-top: 0px !important;
        }
    
        a {
            background-color: white;
            border: none;
    
            padding: 12px 0px 8px 0px;
        }
    
        a.is-open {
            .txt-highlight {
                color: #3c3241;
            }
            
            font-weight: 600;
            border: none;
            border-bottom: 2px solid #5CF100;
        }
    
        li:last-child a {
            border-right: none;
        }
    }

    .my-account-tabs.tabs-content {
        border: none;
    }
}

.tabs-triggers {
    @include clearfix-me();
    position: relative;
    margin-bottom: -2px;
    display: table;
    table-layout: fixed;

    &:not(.multiple-tabs).accordian-to-tab-content:before {
        border: none;
    }

    &:before {
        z-index: 1;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid $colourTabBorderOpen;
    }

    a {

        position: relative;
        display: block;
        border: 1px solid $colourTabBorder;
        border-bottom: 0;
        border-right: 0;
        z-index: 0;
        background-color: $colourTabBg;
        padding: $sSpacingPx 0;
        text-align: center;
        color: $colourTxtTeritary;

        .no-touchevents &:hover {
            text-decoration: none;
        }

        &.is-open,
        &.is-toggled {
            font-weight: $fontBoldWeight;
            color: $colourTxtPrimary;
            z-index: 1;
            border: 1px solid $colourTabBorderOpen;
            border-bottom: 0;
            border-right: 0;
            background-color: $colourTabBgOpen;
        }
    }
    ul {
        display: table-row;
    }
    li {
        display: table-cell;
        width: 2%;
        &:first-child a {
            border-radius: 2px 0 0;
        }
        &:last-child a {
            border-radius: 0 2px 0 0;
            border-right: 1px solid $colourTabBorder;
        }
    }
}


.tabs-content {
    border: 1px solid $colourTabBorder;
    background-color: $white;
    margin-bottom: 18px;

    &.tabs-content-borderless {
        border: 0;
    }

    > .radio-toggle-panel {
        display: none;
        overflow: hidden;
        &.is-open,
        &.is-toggled {
            display: block;
        }
    }
    &.accordian-to-tab-content {
        background: none;
    }
}


.accordian-to-tab-content {
    &.tabs-content:not(.multiple-tabs) {
        border-radius: 4px;
        border-width: 2px;
        border-top: 2px solid $colourTabBorder;
    }
    @include mq($from: l) {
        border-top: 0;
        > a {
            border: 2px solid $colourTabBorder;
        }
    }

    > .radio-toggle-panel {
        clear: both;
        padding: 10px;
        background-color: #E8F6FD;
    }
    > a {
        border-radius: 4px;
        display: none;
    }
    > .tab-trigger {
        background-color: $white;
        a {
            border-bottom-width: 1px;
        }
    }

    @include mq($to: l) {
        &.multiple-tabs {
            border: none;
        }
        a + .radio-toggle-panel {
            border: 0;
            &.is-open,
            &.is-toggled{
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border: 2px solid $colourTabBorder;
                border-top: none;
            }
        }

        > a {
            border: 2px solid $colourTabBorder;
            display: block;
            margin: 5px 0 5px;
            &.is-open,
            &.is-toggled {
                margin-bottom: 0;
                border-bottom-width: 1px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    @include mq($to: m) {
        & > .radio-toggle-panel, a + .radio-toggle-panel{
            padding: 0;
            background: transparent;
            border: none !important;
        }

        &.tabs-content:not(.multiple-tabs) {
            border: none !important;
        }
    }
}

.tabs-floating-button {
    display: none;
}
