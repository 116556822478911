// @category Buttons
// @name Primary button
// @description The primary button
// @order 1
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
//
// @markup
// <button class="btn-primary [state]">Click me</button>

.btn-info {
    @extend %btn;
    color: $colourBtnTextInfo;
    background: $colourBtnBackgroundInfo;
    border: 1px solid $colourBtnBackgroundInfo;
    @include btnHover($colourBtnBackgroundInfo);

    // This adds an .is-open state to the primary button
    &.is-open,
    .no-touchevents &.is-open:hover,
    .no-touchevents &.is-open:focus{
        @extend %btn-is-open;
    }
}
