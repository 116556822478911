// filter button
//
// The one off use case for a checkout button
//
// Markup:
// <button class="btn-filter {$modifiers}">Button (button.btn-secondary)</button>
// <a href="#" class="btn-filter {$modifiers}">Button (a.btn-secondary)</a>
//
// :hover   - Highlight the button when hovered
// :focus   - Focused state
// :active  - Active state
// .is-active - Class based active state
//
// Styleguide 4.2

.btn-filter {
    @extend %btn;
    color:$bark;
    background-color:$white;
    border-radius: 3px;
    height: 44px;
    padding: 0 18px;
}
.btn-filter:hover, .btn-filter.active {
    color: $agnosticBlue;
    border: 1px solid $agnosticBlue;
    background-color:$white;
}
