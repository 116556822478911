%header-button {
    min-width: 44px;

    &:hover {
        background: none !important;
    }

    height: 44px;
    color: $headerNavSearchTxtColour;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    line-height: 20px;

    .no-touchevents &:hover {
        color: $headerNavSearchTxtHover;
        text-decoration: none;
    }
}
