// @category Buttons
// @name Primary button
// @description The primary button
// @order 1
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
//
// @markup
// <button class="btn-primary [state]">Click me</button>

.btn-gtall-26629-primary {
    @extend %btn;
    color: $colourBtnBackgroundPrimaryGtp2992Navy;
    background: $colourBtnBackgroundPrimaryGtp2992Main;
    border: 1px solid $colourBtnBorderPrimary;
    @include btnHover(
        $colourBtnBackgroundPrimaryGtp2992Dark,
        "",
        $colourBtnBackgroundPrimaryGtp2992Navy
    );

    // This adds an .is-open state to the primary button
    &.is-open,
    .no-touchevents &.is-open:hover,
    .no-touchevents &.is-open:focus {
        @extend %btn-is-open;
    }
}
