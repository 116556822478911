// reset button styles
// complitely leave the design to local styles
.btn-reset {
	background: none;
	border: 0 none;
	color: inherit;
}

// @category Buttons
// @categorydescription All the different buttons used on the site

$buttonHeight: 44px;

%btn {
    position: relative;
    display: inline-block;
    border-radius: 2px;
    border: 0;
    padding: 0 6px;
    vertical-align: middle;
    height: $buttonHeight;
    line-height: 40px;
    overflow: hidden;
    text-align: center;

    font-family: inherit;
    font-size: $focusElementFontSize;
    font-weight: $fontNormalWeight;

    .no-touchevents &:hover {
        text-decoration: none;
    }
    &.is-hidden {
        opacity: 0;
        pointer-events: none;
    }
    &.is-waiting {
        .loader-spinner {
            @include spinner($spinnerLoaderLightShadow, 20px, 1);
        }
        .button-contents {
            opacity: 0;
        }
    }

    &.is-disabled {
        border: 1px solid $colourBtnBorderSecondaryDisabled;
        background: $colourBtnDisabled;
        pointer-events: none;
        color: $colourBtnTextDisabledSecondary;
    }
}

%btn-is-open {
    color: inherit;
    position: relative;
    background-color: $white;
    background-image: none;
    border: 1px solid $colourBorder;
    box-shadow: none;
    border-radius: 3px 3px 0 0;
    transition-duration: 0s;
    text-decoration: none;
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        right: -1px;
        height: 15px;
        background-color: $white;
        z-index: 2;
        bottom: -14px;
        border: 1px solid $colourBorder;
        border-width: 0 1px 0 1px;
    }
}

// @category Buttons
// @name Full width button
// @description Button decorator class to make button full width of container
// @exampleextrasmall
//
// @markup
// <button class="btn-primary btn-full-width">Full width button</button>

.btn-full-width {
    width: 100%;
}

.btn-full-width-mobile {
    @include mq($from: xs, $to: m) {
        width: 100%;
    }
}

.btn-rounded-corner {
    border-radius: 50px;
}

// @category Buttons
// @name Wide button
// @description Button decorator class to make button wider than default
// @exampleextrasmall
//
// @markup
// <button class="btn-primary btn-wide">Wide button</button>

.btn-wide {
    width: 200px;
    max-width: 100%;
}

.btn-wider {
    width: 100%;
    max-width: 360px;
}

// @category Buttons
// @name Button with icon
// @description A button with icon
// @exampleextrasmall
//
// @markup
// <button class="btn-primary"><span class="icn-inbox"></span> Inbox</button>
// <button class="btn-secondary"><span class="icn-inbox"></span> Inbox</button>

.btn-icon {
    padding: 0 10px;
}

.btn-icn-left {
    padding-left: 40px;
}

.btn-icn-right {
    padding-right: 40px;
}

.btn-align-left {
    text-align: left;
}

// @category Buttons
// @name Button group
// @description Stack pairs of buttons next to each other
// @exampleextrasmall
//
// @markup
// <ul class="btn-group">
// <li>
// <button class="btn-primary btn-full-width">Button group</button>
// </li>
// <li>
// <button class="btn-secondary btn-full-width">Button group</button>
// </li>
// </ul>

.btn-group {
    @include clearfix-me();
    margin: 0 -6px;
}

.btn-group > li {
    width: 50%;
    float: left;
    padding: 0 6px;
}

// @category Buttons
// @name Button icon left
// @description places the icon in a button to the left of the icon (text is still centred
// @exampleextrasmall
//
// @markup
// <button class="btn-icn-left btn-primary btn-full-width icn-facebook" type="submit">Login with Google</button>
.btn-icn-left:before {
    font-family: 'gumicon';
    position: absolute;
    left: 10px;
    font-size: 23px;
    font-weight: normal;
}

//Import class skins
@import "skin/btn";
@import "skin/btn-primary";
@import "skin/btn-gtall-26629-primary";
@import "skin/btn-secondary";
@import "skin/btn-tertiary";
@import "skin/btn-quaternary";
@import "skin/btn-info";
@import "skin/btn-control";
@import "skin/btn-link";
@import "skin/btn-partnership";
@import "skin/btn-indent";
@import "skin/btn-social";
@import "skin/btn-paypal";
@import "skin/btn-comparison";
@import "skin/btn-header";
@import "skin/btn-navigation";
@import "skin/btn-main-primary";
@import "skin/btn-main-secondary";
@import "skin/btn-filter";
