@import "colours";
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300..600&display=swap");

//Body
$defaultTextColour: $bark;

// Variables grouped by component
//TODO: Split out colours and shadows into own sections then group by component

// Box shadow
$spinnerLoaderDarkShadow: -10px 35px 25px 10px $bark;
$spinnerLoaderLightShadow: -10px 35px 25px 10px $white;
$spinnerLoaderShadow: 0px 15px 25px 10px $berry;
$controlsSecondaryShadow: -1px 2px 2px 1px $black_30pc;
$featuredRibbonShadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
$typeaheadShadow: 0 1px 8px $black_25pc;

// Messages
$successColour: $agnosticGreen;
$warningColour: $agnosticOrange;
$importantColour: $agnosticRed;
$infoColour: $agnosticBlue;
$confirmColour: $berry;

// Borders
$colourBorder: $bark_20pc;
$colourBorderLight: $bark_10pc;
$colourBoxBorder: $branch_10pc;
$colourSectionBorder: $branch_10pc;
$colourActiveBorder: $bark_60pc;
$colourFooterBoarder: $bark_60pc;

// Links
$colourLink: $agnosticBlue;
$colourBlockLinkBg: $agnosticGrey;

// Header
$colourHeaderBorder: $bark;
$colourHeaderDarkBorder: $bark;
$headerNavSearchTxtColour: $myrtle;
$headerNavSearchTxtHover: $bark_40pc;
$colourHeaderLocationPin: $bark_60pc;
$colourHeaderLocationBorder: $bark_60pc;
$colourHeaderSubNavHover: $branch_20pc;
$colourHeaderPopularSearchesBackground: $bark_60pc;
$colourHeaderPopularSearchesHoverBackground: $bark;
$colourHeaderPopularSearchesText: $myrtle;

// Panel
$panelDark: $bark;
$panelDarkText: $white;
$panelTitleBackgroundColour: $agnosticGrey;
$panelTitleIsbeingedited: $bark_20pc;
$panelSummaryTextColour: $bark_80pc;

// Menu
$colourMenuBg: $branch_10pc;
$colourMenuBgHover: $branch_20pc;
$colourRefineMenuBg: $agnosticGrey;
$colourMenuIcnHover: $bark_60pc;

// Refine
$selectedOptionBackground: $branch_10pc;
$boxScrollShadow: inset 0 0 10px $black_15pc;
$boxScrollBottomShadow: inset 0 -10px 10px -10px $black_15pc;
$colourRefineHeaderBg: $bark;
$colourRefineHeaderText: $myrtle;

// Elements:
$colourSelectBg: $branch_10pc;
$colourSelectBgDark: $branch_20pc;
$colourSelectBorder: $branch_20pc;
$colourHrBg: $bark_20pc;

// Placeholder
$placeholderTextColour: $bark_40pc;
$placeholderTextFocusedColour: $bark_20pc;

// Form
$colourLabel: $black;
$colourLabelSmall: $branch_60pc;

$colourTextboxBorder: $bark_20pc;
$shadowTextboxFocusGlow: none;
$textboxFocusBorderColour: $bark_80pc;
$colourTextboxFocusGlowBorder: $bark_80pc;
$colourTextboxDisabledBorder: $bark_10pc;
$colourTextboxDisabledBackground: $branch_10pc;
$textboxPlaceholderTextColour: $bark_40pc;
$textboxPlaceholderTextFocusColour: $bark_20pc;

$colourTextboxInvalidBorder: $agnosticRed;
$shadowTextboxInvalidFocusGlow: inset -1px -1px 0 0 $agnosticRed, inset 1px 1px 0 0 $agnosticRed,
    0 0 8px $agnosticRed;
$colourTextboxInvalidFocusGlowBorder: $agnosticRed;

$colourCheckboxBorder: $branch_40pc;
$colourCheckboxCheckedIcn: $berry;
$colourCheckboxDisabledIcn: $branch_40pc;
$colourCheckboxBg: $white;
$colourCheckboxOnOffBeforeBg: $bark_80pc;
$colourCheckboxOnOffBeforeBorder: $black_5pc;
$colourCheckboxOnOffAfterBorder: $branch_40pc;
$colourCheckboxOnOffCheckedAfterBg: $berry;
$colourCheckboxOnOffCheckedBeforeBorder: $branch_40pc;
$colourCheckboxOnOffCheckedAfterBorder: $branch_40pc;
$shadowCheckboxOnOffHover: 0 0 5px $black_30pc;
$shadowCheckboxSwitchBefore: inset 0 0 8px $black_20pc;
$shadowCheckboxSwitchBeforeHover: $bark_80pc;
$shadowCheckboxSwitchDisabledBeforeHover: inset 0 0 8px $black_20pc;
$colourCheckboxHighlightHoverBg: $bark_20pc;
$colourCheckboxHighlightCheckedBg: $white;
$colourCheckboxHighlightCheckedIcn: $berry;
$colourCheckboxDisabledBorder: $branch_10pc;
$colourCheckmarkValid: $agnosticGreen;

$selectBorderColour: $bark_20pc;

// Image upload
// ------------------------------------
$imageUploadImagePrimaryBackground: $agnosticGrey;
$imageUploadImageSecondaryBackground: $agnosticGrey;
$imageUploadDeleteIconBackground: $bark_60pc;
$imageUploadDeleteText: $white;
$imageUploadDeleteIconFocusBackground: $bark;

// Icons
$colourIcnSharePinterest: $pinterest;
$colourIcnShareTwitter: $twitterLightBlue;
$colourIcnShareFacebook: $facebook;
$colourIcnShareFacebookHover: $facebookDark;
$colourIcnShareWhatsapp: $whatsappLightGreen;
$colourIcnShareMessenger: $messengerBlue;

// Lightbox
$colourLightboxBg: $bark_dark;
$colourLightboxBorder: $bark_dark;
$colourLightboxClose: $branch_60pc;

// Ribbons
$colourRibbonFeatured: $white;
$colourRibbonFeaturedText: $agnosticBlue;
$colourRibbonDiscount: $agnosticGreen;

// Labels
$colourLabelPositive: $agnosticGreen;
$colourLabelUrgent: $agnosticRed;
$colourLabelFeatured: $agnosticBlue;
$colourLabelSpotlight: $agnosticGreen;
$colourLabelDeleted: $blade;
$colourLabelExpired: $bark_80pc;

// Box Title
$colourBoxLink: $bark;
$colourBoxTitleBg: $branch_10pc;
$boxLineHeight: 14px;
$colourBoxBgSecondary: $branch_10pc;

// Box Pullout
$boxPulloutColour: $branch_10pc;

// Box Info
$boxInfoColour: $blade_10pc;
$boxInfoTextColour: $blade;

// Tabs
$colourTabBorder: $colourBorder;
$colourTabBorderOpen: $colourBorder;
$colourTabBg: $branch_10pc;
$colourTabBgOpen: $white;

// Tabs Simple
$colourTabSimpleText: $bark_80pc;
$colourTabSimpleTextActive: $bark;
$colourTabSimpleTextBackground: $branch_10pc;

// Typography
$colourTxtPrimary: $bark;
$colourTxtSecondary: $bark_80pc;
$colourTxtTeritary: $bark_60pc;
$colourTxtQuaternary: $bark_40pc;
$colourTxtSelected: $bark_60pc;
$colourTxtError: $agnosticRed;
$colourTxtHighlighted: $agnosticGreen;

$fontSizeMicro: 13px;
$fontSizeSub: 14px;
$fontSizeLarge: 18px;
$fontSizeXLarge: 22px;
$fontSizeHero: 40px;

$fontSizeMicroKerning: 0.3px;

// Lists
$colourColumnListNumber: $branch_60pc;

// Labels
$colorTypeAheadSelected: $berry;
$colourTypeAheadHover: $berry;

// Notification
$colorNotificationBackground: $white;
$colorNotificationSuccess: $agnosticGreen;
$colorNotificationSuccessBackground: $agnosticGreenLight;

// Manage Ads Pro
$manageAdsProStatusDeletedText: $bark;
$manageAdsProBackground: $branch_10pc;
$manageAdsProErrorBackground: $berry;
$manageAdsProTableBackground: $white;
$manageAdsProWarningText: $agnosticRed;
$manageAdsProLocationTextColour: $bark_80pc;
$manageAdsProStatusPositive: $agnosticGreen;
$manageAdsProDiscountedPrice: $agnosticGreen;
$manageAdsProTableBorderColour: $bark_20pc;

$manageAdsProAdsPerPageLinkColour: $agnosticBlue;

// Manage Ads
$manageAdsListingsBackgroundColour: $white;
$manageAdsListingsAlternativeBackgroundColour: $branch_10pc;
$manageAdsLinkColour: $bark;
$manageAdsNavigationFocusBackground: $branch_20pc;
$manageAdsNavigationIconsColour: $bark_80pc;
$manageAdsNavigationActiveIcons: $blade;
$manageAdsNavBoxBackgroundColour: $white;
$manageAdsPagninationFocusBackground: $branch_20pc;
$manageAdsPagninationNotActiveBackground: $branch_10pc;
$manageAdsBorderColour: $branch_20pc;
$manageAdsFooterBoxShadowColour: $branch_10pc;
$manageAdsFooterStatusBorderColour: $white;
$manageAdsFooterBackgroundColour: $white;
$manageAdsFooterStatsDividerColour: $branch_20pc;
$featureUpsellPrice: $bark_80pc;
$featureUpsellDuration: $bark_80pc;
$featureUpsellBgColour: $bark_10pc;
$featureUpsellSelectedBgColour: $white;

// Message Centre
$messageCentreConversationTxtColour: $bark_80pc;
$messageCentreMessageBackgroundColour: $white;
$messageCentreMessageInfoTextColour: $agnosticBlue;
$messageCentreConversationRightTxtColour: $white;
$messageCentreSpeechBalloonRightBackground: $agnosticBlue;
$messageCentreSpeachBalloonLeftBackground: $bark_20pc;
$messageCentreReviewBalloonBackground: $agnosticGreen_10pc;
$messageCentreReviewBalloonTextColour: $agnosticGreen;
$messageCentreHeaderBackground: $white;
$messagesMobileHeaderBackground: $bark;
$messageCentreHeaderLinkColour: $white;
$messageCentreFooterBackground: $bark_10pc;
$messageCentreFooterBorderColour: $bark_20pc;
$messageCentreSelectedConverstaion: $branch_10pc;
$messageCentreMessagesBackgroundColour: $bark_20pc;
$messageCentreNewMessageBackgroundColour: $berry;
$messageCentreUnreadMessagesIcn: $berry;
$messageCentreHoverColour: $agnosticGrey;
$messageCentreStatusIconColour: $bark_80pc;
$messageCentreScrollHintBgColour: $agnosticBlue;
$messageCentreBannerBackground: $blade;
$messageCentreBannerTextColour: $white;

// Sell your item
$syiPageBackgroundColour: $branch_5pc;

// Gradients (use very very sparingly for better performance!)
// --------------------------

// Truncate gradient
//$gradTruncateParagraph: $white_50pc, $white 50%, $white;

// Double Gumtree green - header
$colourHeaderPrimary: $bark;
$colourHeaderSecondary: $bark_20pc;
$colourHeaderTextPrimary: $myrtle;
$colourHeaderNoTouchLinkHover: $bark_80pc;
$colourHeaderLinkHover: $myrtle_dark;
$headerBtnHoverColour: $white;
$colourHeaderTitle: $bark;
$headerBtnBorderColour: $berry;
$headerBtnIcnColour: $berry;
$headerBtnBackgroundHoverColour: $berry;

// Box Article
$colourBoxArticleTitle: $colourLink;

// Nav Categories (for home)
$categoriesShadow: 0 1px 3px 0 $black_40pc;
$categoriesMegaMenuShadow: 0 4px 15px 0 $black_20pc;

// Category selector dropdowns
$categorySelectorLoadingColour: $bark;
$catergorySelectorIsDrilledCatergoryNameBackgroundColour: $branch_10pc;
$categoriesDropdownShadow: 0 4px 15px 0 $black_20pc;
$categoryListSelectedParent: $agnosticGrey;
$categoryNameHoverBackgroundColour: $agnosticGrey;
$categoryListIconColour: $branch_60pc;

// Grid
// ------------------------------------
$grid-columns: 12;
$grid-gutter-width: 16px;

//TODO : If this is the defaults it should be defined in the grid mixin
$grid-gutter-widths: ((xs 6px) (s 12px) (m 20px) (l 40px) (xl 60px)) !default;

// Whitespace
// ------------------------------------
$pageGutter: 10px;

// Typography and vertical rhythm
// ------------------------------------
$baseFontSize: 15px;
$baseFontStack: "Readex Pro", sans-serif;
$mainFontStack: "Readex Pro", sans-serif;
$baseLineHeight: 1.4;
$baseLineHeightPx: ($baseFontSize * $baseLineHeight);
$fontNormalWeight: 330;
$fontBoldWeight: 700;
$focusElementFontSize: 16px;

$xsSpacingPx: 6px;
$sSpacingPx: 12px;
$mSpacingPx: 18px;
$lSpacingPx: 24px;
$xlSpacingPx: 32px;
$xxlSpacingPx: 48px;

// Buttons ===========================
$btnDefaultTextColour: $agnosticBlue;
$btnDefaultHoverTextColour: $bark_80pc;
$colourBtnDisabled: $agnosticGrey;
$colourBtnDisabledTextColour: $bark_20pc;
$colourBtnDisabledGtp2992Main: $gtp_2992_grey;
$colourBtnDisabledGtp2992Dark: $gtp_2992_dark_grey;

// --- Primary
$colourBtnBackgroundPrimary: $berry;
$colourBtnBackgroundPrimaryHover: $berry_dark;
$colourBtnBorderPrimary: transparent;
$colourBtnTextPrimary: $white;
$colourBtnTextPrimaryHover: $white;
$colourBtnBackgroundGtall26629Primary: $gtall_26629_green;
$colourBtnBackgroundGtall26629PrimaryHover: $gtall_26629_green_dark;
$colourBtnBackgroundPrimaryGtp2992Main: $gtp_2992_green;
$colourBtnBackgroundPrimaryGtp2992Dark: $gtp_2992_green_dark;
$colourBtnBackgroundPrimaryGtp2992Navy: $gtp_2992_navy;

// --- Secondary
$colourBtnBackgroundSecondary: $white;
$colourBtnBackgroundSecondaryHover: $bark;
$colourBtnBorderSecondary: $bark_40pc;
$colourBtnBorderSecondaryDisabled: $branch_20pc;
$colourBtnTextDisabledSecondaryPoint: $bark_40pc;
$colourBtnTextSecondary: $bark;
$colourBtnTextDisabledSecondary: $black_20pc;
$colourBtnTextSecondaryHover: $white;
$colourBtnBackgroundSecondaryGtp2992Main: $white;
$colourBtnBackgroundSecondaryGtp2992Dark: $gtp_2992_light_blue;
$colourBtnBackgroundSecondaryGtp2992Navy: $gtp_2992_navy;

// --- Tertiary
$colourBtnBackgroundTertiary: $white;
$colourBtnBackgroundTertiaryHover: $branch_10pc;
$colourBtnBorderTertiary: $white;
$colourBtnBorderBottomTertiary: $bark_20pc;
$colourBtnTextTertiary: $agnosticBlue;
$colourBtnBackgroundDisabledTertiary: $bark_20pc;
$colourBtnBorderDisabledTertiary: $bark_20pc;
$colourBtnBorderBottomDisabledTertiary: $bark_60pc;
$colourBtnTextDisabledTertiary: $black_20pc;

// --- Info
$colourBtnBackgroundInfo: $blade_10pc;
$colourBtnTextInfo: $blade;

// --- Main Primary
$colourBtnBackgroundMainPrimary: $agnosticGreen;
$colourBtnBackgroundMainPrimaryHover: $leaf_dark;
$colourBtnBorderMainPrimary: $agnosticGreen;
$colourBtnTextMainPrimary: $white;

// --- Main Secondary
$colourBtnBackgroundMainSecondary: $white;
$colourBtnBackgroundMainSecondaryHover: $agnosticGreen;
$colourBtnTextMainSecondaryHover: $white;
$colourBtnBorderMainSecondary: $agnosticGreen;
$colourBtnTextMainSecondary: $agnosticGreen;

$shadowBtn: 2px 2px 4px $black_15pc;

// --- Navigation
$colourBtnTextNavigation: $berry;
$colourBtnTextNavigationHover: $berry_dark;

// --- Control
$colourBtnColour: $branch_60pc;
$colourBtnBackgroundControl: $branch_10pc;
$colourBtnBackgroundControlDisabled: $bark_20pc;

// Footer colours
$footerBackgroundColour: $bark;
$footerBorderColour: transparent;
$footerTextPrimaryColour: $myrtle;
$footerTextSecondaryColour: $myrtle;
$footerTextCopyWriteColour: $bark_40pc;
$footerLinksColor: $agnosticBlueLight;
$footerHeadingColor: $bark_40pc;
$socialMediaButtonsColour: $white;
$socialMediaButtonsBgColour: $bark_80pc;
$socialMediaButtonsHoverBgColour: $bark;

// Pagination
$paginationActiveLinkColour: $bark;
$paginationBackgroundColour: $white;
$paginationHoverBackgroundColour: $agnosticGrey;
$paginationActiveBackgroundColour: none;
$paginationBorderColour: $branch_20pc;
$paginationActiveBorderColour: $bark;
$paginationActiveHoverBackgroundColour: transparent;
$paginationActiveTestColourHover: $bark;
$paginationFromMoreBackgroundColour: $branch_10pc;
$paginationElipsisColour: $branch_60pc;
$paginationElipsisBackgroundColour: $white;
$pagnationPagPgerBackgroundColour: $white;
$pagnationPagPgerBackgroundHoverColour: $branch_10pc;
$paginationBtnCancelTextColour: $bark_80pc;

$refineAcordionTextColour: $bark_80pc;

// Stripped List
$stripListEvenBackgroundColour: $white;
$stripListOddBackgroundColour: $branch_10pc;
$stripListBorderBottomColour: $branch_10pc;

// Tooltips
$tooltipBackgroundColour: $white;
$tooltipShadowColour: $bark_20pc;
$tooltipHoverColour: $bark;

// Alternate row
$alternateRowBackgroundColour: $branch_10pc;

//Listing Mini
$listingMiniShadowColour: $bark_20pc;

// Listing Horizontal
$colourListingHorizontalBorderRight: $branch_20pc;

// Listing simple
$colourListingSimpleBackground: $white;
$colourListingSimpleBlankShadow: $branch_60pc;
$colourListingSimpleBlankText: $bark_60pc;
$colourListingSimpleBlankIcon: $berry;
$colourListingSimplePriceBackground: $agnosticBlue;
$colourListingSimplePriceText: $white;
$colourListingSimpleShadow: $bark_20pc;

// Letter box colours
$letterboxWarningBackground: $agnosticRed;
$letterboxInfoBackground: $agnosticGreen;
$letterboxHighlightBackground: $agnosticBlue;
$letterboxTextColour: $white;

// Advertising
$colourAdvertisingTextLinkBackground: $white;
$stickyBannerSpace: 0;

// Horizontal rule
$horizontalRuleBorderColour: $branch_20pc;
$horizontalRuleTextBgColour: $white;

// Layer Indexes
$layerIndexLow: 10;
$layerIndexMedium: 50;
$layerIndexHigh: 100;
