// @category Buttons
// @name Control button
// @description Used for ext and previous controllers on filmstrip
// @exampleextrasmall
//
// @markup
// <a class="btn-control"><span class="icn-chevron-l" aria-hidden="true"></span></a>

.btn-control {
    display: inline-block;
    background-color: $colourBtnBackgroundControl;
    color: $colourBtnColour;
    border-radius: 3px;
    box-shadow: $controlsSecondaryShadow;
    padding: 8px 10px 6px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.btn-control.is-disabled {
    cursor: default;
    color: $colourBtnBackgroundControlDisabled;
}
