// @category Box
// @name Box Info
// @description A version of box to display info
// @order 4
//
// @markup
// <div class="box-info [state]">
//      <div class="box-padding">Content of the box</div>
// </div>

.box-info {
    @extend %box;
    background-color: $boxInfoColour;
    border: none;
    color: $boxInfoTextColour;
    border-radius: 0;
}
