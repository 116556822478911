// @category Utils
// @name Overflow styles
// @description Set element's overflow to hidden
//
// @markup
// <div class="overflow-hidden">.overflow-hidden</div>

.overflow-hidden {
    overflow: hidden;
}
