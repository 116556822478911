// @category Buttons
// @name Main secondary button
// @description The main secondary button
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
// @theme dark
// @order 3
//
// @markup
// <button class="btn-main-secondary">Click me</button>

.btn-main-secondary {
    @extend %btn;
    color: $colourBtnTextMainSecondary;
    background: $colourBtnBackgroundMainSecondary;
    @include btnHover($colour: $colourBtnBackgroundMainSecondaryHover, $textColour: $colourBtnTextMainSecondaryHover);
    border: 1px solid $colourBtnBorderMainSecondary;
}
