@mixin createGridInlineByMq($mqArray) {
  @each $breakpoint in $mq-breakpoints {
    $breakpoint-name:  nth($breakpoint, 1);
    @if $breakpoint-name == "xs" {
      @include inlineGrid($breakpoint-name);
    } @else {
      @include mq($from:#{$breakpoint-name}){
        @include inlineGrid($breakpoint-name);
      }
    }
  }
}

@mixin inlineGrid($breakpoint-name){
  .grid-inline-#{$breakpoint-name} {

    .inline-list-breaks & {
      border-left:1px solid grey;
    }

    // Blowing my mind ever so slightly with scss!!!
    .inline-list-breaks &:first-child{
      border-left:none;
    }

    display:inline-block;
    width:auto;

  }
}
