// @category Sass Mixins
// @name Translate3D (for 2d translation) with fallback
// @description Adds css to translate and transition elements with a positioning fallback
// @noexample
// @markup
// @include translate3dFallBack( $x, $y, $z, $fallback );

@mixin translate3dFallBack( $x, $y, $z, $fallback ){
    .csstransforms & {
        transform: translate3d( $x, $y, $z );
    }
    .no-csstransforms &,
    .ie9.csstransforms & {
        $fallback: $x;
    }
}

// @category Sass Mixins
// @name Translate3D (for 2d translation) with fallback for toggle use
// @description Adds css to translate and transition elements with a positioning fallback
// @noexample
// @markup
// @include translate3dToggle($toggleClass,
// $startX: 0,
// $startY: 0,
// $endX: 0,
// $endY: 0,
// $fallbackOffsetMargin: auto,
// $easing: ease-in-out,
// $positioned: relative,
// $zIndex: auto,
// $cancelFrom: null,
// $cascadeStyles: null,
// $ancestorSelector: null,
// $baseStyles: true)


//Usages notes:
//++++++++++++++++++
//Toggle JS
//-----------------------------------------------//
//The mixin relies on Toggle JS to work. The mixin should be called against the element that has the toggle class
//applied, or an optional $ancestorSelector should be passed in that has the toggle class applied, and the toggle class
//name should be passed into the mixin as $toggleClass [required].
//
//Optopnal argument/s: $start and $end
//-----------------------------------------------//
//Specify the start and end positions of the element to translate. At least one of the $start or $end values on either
//axis must be different to zero to achieve a translation. The default is zero for both start and end on both X and Y
//axes. The mixin will appply the relevant top and left values for fallback transitions depenedent on which axis you are
//translating against.
//
//Optopnal argument: $fallbackOffsetMargin
//-----------------------------------------------//
//Fallback offset class is used to normalize top/left position against translate3d(0). At translate3d(0), the position
//of the element will be equivalent to its static position; whereas top:0 or left:0 will talke its position from its
//nearest positioned ancestor. In this case you may use $fallbackOffsetMargin to normalize this default position to
//match the element's position at translate3d(0) so the transition start and end positions match for both types of
//transition using the same distance value.
//
//Optopnal argument: $positioned and $zIndex
//-----------------------------------------------//
//Translatable elements must be positioned to all the fallback transition to work. For most cases the default value of
//relative will be sufficient. However, where the element has to start off-screen or under another element, absolute
//will most likely be used. You can optionally set the z-index of the translated element as required.
//
//Optional argument: $cancelFrom
//-----------------------------------------------//
//Allows you to pass in a breakpoint from which the translation should be reset to pre-toggle state by default.
//
//Optional argument: $ancestorSelector
//-----------------------------------------------//
//Allows you to target the translated element by toggling a class on an ancestor element
//

@mixin translate3dToggle($toggleClass,
$startX: 0,
$startY: 0,
$endX: 0,
$endY: 0,
$fallbackOffsetMargin: auto,
$easing: ease-in-out,
$positioned: relative,
$zIndex: auto,
$cancelFrom: null,
$cascadeStyles: null,
$ancestorSelector: null,
$baseStyles: true) {

    @if $baseStyles == true {
        position: $positioned;
        z-index: $zIndex;
    }

    // TODO : modernizr now also adds csstransforms3d class as well. Refactor to use this

    .csstransforms & {
        transform: translate3d($startX, $startY, 0);
        transition: transform 0.3s $easing;
    }

    @if $ancestorSelector == null {
        .csstransforms &.#{$toggleClass} {
            transform: translate3d($endX, $endY, 0);
        }
        .ie9.csstransforms &.#{$toggleClass} {
            transform: translate3d(0, 0, 0);
        }
    } @else {
        .csstransforms #{$ancestorSelector}.#{$toggleClass} & {
            transform: translate3d($endX, $endY, 0);
        }
        .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
            transform: translate3d(0, 0, 0);
        }
    }

    @if $startX != 0 or $endX != 0 {
        @if $ancestorSelector == null {
            .no-csstransforms &,
            .ie9.csstransforms &,
            .no-csstransforms &.#{$toggleClass},
            .ie9.csstransforms &.#{$toggleClass} {
                transition: left 0.3s $easing;
            }
        } @else {
            .no-csstransforms &,
            .ie9.csstransforms &,
            .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
            .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                transition: left 0.3s $easing;
            }
        }
        .no-csstransforms &,
        .ie9.csstransforms & {
            left: $startX;
            margin-left: $fallbackOffsetMargin;
        }

        @if $ancestorSelector == null {
            .no-csstransforms &.#{$toggleClass},
            .ie9.csstransforms &.#{$toggleClass} {
                left: $endX;
            }
        } @else {
            .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
            .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                left: $endX;
            }
        }
    }

    @if $startY != 0 or $endY != 0 {
        @if $ancestorSelector == null {
            .no-csstransforms &,
            .ie9.csstransforms &,
            .no-csstransforms &.#{$toggleClass},
            .ie9.csstransforms &.#{$toggleClass} {
                transition: top 0.3s $easing;
            }
        } @else {
            .no-csstransforms &,
            .ie9.csstransforms &,
            .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
            .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                transition: top 0.3s $easing;
            }
        }

        .no-csstransforms &,
        .ie9.csstransforms & {
            top: $startY;
            margin-top: $fallbackOffsetMargin;
        }

        @if $ancestorSelector == null {
            .no-csstransforms &.#{$toggleClass},
            .ie9.csstransforms &.#{$toggleClass} {
                top: $endY;
            }
        } @else {
            .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
            .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                top: $endY;
            }
        }
    }

    //Cancel the translation from a given break point
    @if $cancelFrom != null {
        @include mq($from: #{$cancelFrom}) {
            @if $ancestorSelector == null {
                .csstransforms &.#{$toggleClass} {
                    transform: translate3d($startX, $startY, 0);
                }
                else {
                    .csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                       transform: translate3d($startX, $startY, 0);
                    }
                }
            }

            @if $startX != 0 or $endX != 0 {
                @if $ancestorSelector == null {
                    .no-csstransforms &.#{$toggleClass},
                    .ie9.csstransforms &.#{$toggleClass} {
                        left: $startX;
                        margin-left: auto;
                    }
                }
                else {
                    .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
                    .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                        left: $startX;
                        margin-left: auto;
                    }
                }
            }

            @if $startY != 0 or $endY != 0 {
                @if $ancestorSelector == null {
                    .no-csstransforms &.#{$toggleClass},
                    .ie9.csstransforms &.#{$toggleClass} {
                        top: $startY;
                        margin-top: auto;
                    }
                }
                else {
                    .no-csstransforms #{$ancestorSelector}.#{$toggleClass} &,
                    .ie9.csstransforms #{$ancestorSelector}.#{$toggleClass} & {
                        top: $startY;
                        margin-top: auto;
                    }
                }
            }
        }
    }
}
