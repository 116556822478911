// @category Sass Mixins
// @name Loader Spinner
// @description Outputs the CSS for the a loading spinnner
// @noexample
// @markup
// @include spinner($shadow, $size, $opacity);

@mixin spinner($shadow, $size, $opacity) {

    $margin: 5px;

    border-radius: $size;
    opacity: $opacity;
    margin-top: $size / 2  * -1 - $margin;
    margin-left:$size / 2  * -1 - $margin;


    &:before {
        width: $size;
        height: $size;
        margin: $margin;
        border-radius: $size;
        box-shadow: $shadow;
    }
}
