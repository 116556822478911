@import "../../../../../../../frontend/src/common/ui-library/src/base/colors.scss";

// Generate file - icomoon.io
// http://icomoon.io/
// Add an icon to the asset
// -  /seller-public-static-assets/src/main/webapp/responsive/font/gumtree/ (with the new downloaded ones)
// - replace this files with CSS created (but leave font-face and [class^... blocks intact)

@font-face {
    font-family: "gumicon";
    font-display: swap;
    src: url("../../../font/gumtree/gumtree.eot");
    src: url("../../../font/gumtree/gumtree.eot") format("embedded-opentype"),
        url("../../../font/gumtree/gumtree.woff2") format("woff2"),
        url("../../../font/gumtree/gumtree.woff") format("woff"),
        url("../../../font/gumtree/gumtree.ttf") format("truetype"),
        url("../../../font/gumtree/gumtree.svg") format("svg");
}

%icon-font,
[class^="icn-"],
[class*=" icn-"] {
    font-family: "gumicon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: relative;
    font-size: 16px;
    top: -2px;
    left: 0;
    line-height: inherit;
    vertical-align: middle;
}

//TODO Theres a lot of defined values in the above, seems to much
%icon-font-lite {
    font-family: "gumicon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 135%;
    line-height: inherit;
    vertical-align: middle;
}

.gumicon {
    @extend %icon-font-lite;
}

.icn-yellow {
    color: #efc636;
}

// ================================= REPLACE ONLY BELOW THIS LINE ==================================

.icn-file:before {
    content: "\ff919";
}
.icn-shield:before {
    content: "\e929";
}
.icn-download:before {
    content: "\e928";
}
.icn-chat-balloon:before {
    content: "\e910";
}
.icn-clock:before {
    content: "\e927";
}
.icn-review-balloon:before {
    content: "\e925";
}
.icn-half-star:before {
    content: "\1f5e6";
}
.icn-full-star:before {
    content: "\e923";
}
.icn-empty-star:before {
    content: "\e924";
}
.icn-empty-heart-white:before {
    content: "\e91c";
    color: #fff;
}
.icn-empty-heart-berry:before {
    content: "\e91c";
    color: #e75462;
}
.icn-empty-heart-bark:before {
    content: "\e91c";
}
.icn-empty-heart-bark-80:before {
    content: "\e91c";
    color: #635b67;
}
.icn-empty-heart-bark-60:before {
    content: "\e91c";
    color: #8a848d;
}
.icn-full-heart-white:before {
    content: "\e91d";
    color: #fff;
}
.icn-full-heart-berry:before {
    content: "\e91d";
    color: #e75462;
}
.icn-full-heart-bark:before {
    content: "\e91d";
}
.icn-full-heart-bark-80:before {
    content: "\e91d";
    color: #635b67;
}
.icn-full-heart-bark-60:before {
    content: "\e91d";
    color: #8a848d;
}
.icn-full-heart:before {
    content: "\e91d";
}
.icn-car-search:before {
    content: "\f999";
}
.icn-speedometer:before {
    content: "\e911";
}
.icn-fuel:before {
    content: "\e912";
}
.icn-info-outline:before {
    content: "\e915";
}
.icn-lightbulb:before {
    content: "\e914";
}
.icn-catering:before {
    content: "\e90f";
}
.icn-construction:before {
    content: "\e913";
}
.icn-driving:before {
    content: "\e916";
}
.icn-sales:before {
    content: "\e917";
}
.icn-healthcare:before {
    content: "\e918";
}
.icn-all_categories:before {
    content: "\e904";
}
.icn-car:before {
    content: "\e625";
}
.icn-tag:before {
    content: "\e603";
}
.icn-tag-solid {
    &:before {
        content: "\e603";
    }
    &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: white;
        position: absolute;
        top: 5px;
        left: 3px;
        z-index: 1;
        transform: rotate(45deg);
        transition: 0.1s ease;
    }
}
.icn-tie:before {
    content: "\e602";
}
.icn-house:before {
    content: "\e610";
}
.icn-paw:before {
    content: "\e60c";
}
.icn-people:before {
    content: "\e60b";
}
.icn-spanner:before {
    content: "\e608";
}
.icn-burger:before {
    content: "\e627";
}
.icn-chevron-r:before {
    content: "\e634";
}
.icn-chevron-l:before {
    content: "\e633";
}
.icn-chevron-u:before {
    content: "\e623";
}
.icn-chevron-d:before {
    content: "\e622";
}
.icn-clear:before {
    content: "\e62f";
}
.icn-close:before {
    content: "\e61b";
}
.icn-dropdown:before {
    content: "\e617";
}
.icn-spinner:before {
    content: "\e605";
}
.icn-more-vert:before {
    content: "\e907";
}
.icn-circ-compose:before {
    content: "\e641";
}
.icn-plus:before {
    content: "\e632";
}
.icn-minus:before {
    content: "\e631";
}
.icn-phone:before {
    content: "\e609";
}
.icn-envelope:before {
    content: "\e616";
}
.icn-circ-email:before {
    content: "\e62e";
}
.icn-network:before {
    content: "\e60d";
}
.icn-beacon:before {
    content: "\e629";
}
.icn-circ-beacon:before {
    content: "\e682";
}
.icn-magnifying-glass:before {
    content: "\e60e";
}
.icn-refine:before {
    content: "\e800";
}
.icn-bell:before {
    content: "\e90c";
}
.icn-bell-plus:before {
    content: "\e90a";
}
.icn-bell-tick:before {
    content: "\e90b";
}
.icn-pin:before {
    content: "\e902";
}
.icn-camera:before {
    content: "\e626";
}
.icn-camera-add:before {
    content: "\e905";
}
.icn-folder:before {
    content: "\e613";
}
.icn-bin:before {
    content: "\e628";
}
.icn-crayon:before {
    content: "\e61a";
}
.icn-chart:before {
    content: "\e624";
}
.icn-circ-up:before {
    content: "\e606";
}
.icn-circ-down:before {
    content: "\e61c";
}
.icn-graph-growing:before {
    content: "\e63b";
}
.icn-cart:before {
    content: "\e903";
}
.icn-vhc:before {
    content: "\e90d";
}
.icn-case:before {
    content: "\e901";
}
.icn-person:before {
    content: "\e607";
}
.icn-person-plus:before {
    content: "\e60a";
}
.icn-circ-question-mark:before {
    content: "\e61d";
}
.icn-inbox:before {
    content: "\e60f";
}
.icn-exit:before {
    content: "\e90e";
}
.icn-video:before {
    content: "\e601";
}
.icn-magnifying-glass-zoom:before {
    content: "\e63a";
}
.icn-file1:before {
    content: "\e636";
}
.icn-info2:before {
    content: "\e635";
}
.icn-checkbox-switch:before {
    content: "\e906";
}
.icn-circ-plus:before {
    content: "\e61e";
}
.icn-circ-plus-green:before {
    content: "\e61e";
    color: $leaf;
}
.icn-check:before {
    content: "\e630";
}
.icn-circ-check:before {
    content: "\e63c";
}
.icn-circ-alert:before {
    content: "\e621";
}
.icn-warning:before {
    content: "\e600";
}
.icn-lock:before {
    content: "\e908";
}
.icn-circ-error:before {
    content: "\e640";
}
.icn-eye:before {
    content: "\e615";
}
.icn-circ-twitter:before {
    content: "\e62a";
}
.icn-circ-googleplus:before {
    content: "\e62c";
}
.icn-circ-facebook:before {
    content: "\e62d";
}
.icn-circ-pinterest:before {
    content: "\e62b";
}
.icn-messenger:before {
    content: "\e909";
}
.icn-whatsapp:before {
    content: "\e900";
}
.icn-twitter:before {
    content: "\e63f";
}
.icn-googleplus:before {
    content: "\e63d";
}
.icn-facebook:before {
    content: "\e63e";
}
.icn-fb-logo:before {
    content: "\ea8d";
}
.icn-flag:before {
    content: "\e614";
}
