// Brand
$bark: #3c3241;
$bark--dark: #1e1921;
$leaf: #72ef36;
$leaf--dark: #39781b;
$leaf--light: #f9fdf7;
$berry: #e75462;
$berry--dark: #b54343;
$berry--light: #f7f0f0;
$blade: #0d475c;
$branch: #4b444b;
$branch--dark: #262226;
$myrtle: #f0ece6;
$myrtle--dark: #787673;

// Jobs
$maple: #f16f3c;
$maple--dark: #b83f0e;
// Jobs

$bark--80: #635b67;
$bark--60: #8a848d;
$bark--40: #b1adb3;
$bark--20: #d8d6d9;
$bark--10: #f1f0f1;

$branch--60: #938f93;
$branch--40: #b7b4b7;
$branch--20: #dbdadb;
$branch--10: #f1f1f1;
$branch--5: #f6f5f6;

$blade: #0d475c;
$blade--70: #557e8d;
$blade--10: #e6ecee;

// Agnostic
$blue: #007fb0;
$blue--10: #e6f2f7;
$blue--40: #99ccdf;
$red: #e52815;
$red--10: #fdecea;
$yellow: #efc636;
$orange: #ff9900;
$green: #52a744;
$green--10: #eef8ed;
$white: #fff;
$lightgrey: #fafafa;
$black: #000;

// Third party
$facebook: #37589a;
$twitter: #38a8e0;
$pinterest: #c91618;

$vrnTextboxColour: #fdec30;
