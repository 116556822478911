// @category Site chrome
// @name Header
// @info Does not display perfectly in styleguide due to breakpoint mismatch, might need to look at improving this
//
// @markup
// [#assign pageOptions = {} /]
// [#assign openSearch = true /]
// [#assign gumtreehost = "" /]
// [#assign mygumtreehost = "" /]
// [#assign model = {"core" :{"category" : {"name": "", "seoName" : "", "depth": 1}, "distanceFilter": [], "host": "", "myGumtreeHost":"", "messageCentreLinkEnabled": false}} /]
// [#include "/@inc/shared/freemarker/pageChrome/header/_header.ftl" /]
@import "header-button";
@import "header-notification";

@import "../../../../../../../frontend/src/common/ui-library/src/base/color-variables.scss";
@import "../../../../../../../frontend/src/common/ui-library/src/base/colors.scss";
@import "../../../../../../../frontend/src/common/ui-library/src/base/z-indexes.scss";
@import "../../../../../../../frontend/src/common/ui-library/src/base/boxes.scss";
@import "../../../../../../../frontend/src/common/ui-library/src/base/fonts.scss";

$search-arrow-width: 10px;
$search-arrow-height: 8px;

.header {
    background-color: $blade;
    color: $colourHeaderTextPrimary;
    position: relative;
    z-index: 90;
    transition: margin-bottom 333ms ease-in-out;

    @include mq($to: m) {
        will-change: transform;
        transition: margin-bottom 333ms ease-in-out, transform 700ms ease;
    }

    &.is-category-header-active {
        z-index: $z-index--medium-high + 1;
        .header-inner-wrapper {
            z-index: initial;
        }
    }

    .header-inner-wrapper {
        background-color: $blade;
        z-index: $z-index--medium-high + 2;
        position: relative;
    }
}

.inExperimentGTALL20594 {
    position: sticky;
    top: 0;
}

.no-touchevents .header-nav-link:hover {
    color: $colourHeaderNoTouchLinkHover;
}

.header .header-nav-submenu,
#mobile-additional-links {
    z-index: $z-index--medium-high + 2;
    position: absolute;
    display: none;
    opacity: 0;
    transition: opacity 180ms ease-in;
    top: 54px;
    border: 1px solid $colourHeaderBorder;
    min-width: 200px;

    .payment-link {
        background: none !important;
        button {
            border-width: 0px !important;
        }
    }

    .payment-link > div > .pay-now-in-message {
        color: $white !important;
        font-weight: 400 !important;
        font-size: 14px;
        padding-left: 12px !important;
    }

    .payment-link .payment-request-error {
        .icon--info-outline {
            display: none;
        }

        @include mq($to: m) {
            display: none;
        }
    }

    .payment-link > .pay-now-header {
        width: 100%;

        .pay-now-in-message {
            text-align: left !important;
            width: 100%;
            background: none !important;

            @include mq($from: s, $to: m) {
                width: auto;
            }

            .icon--lock {
                display: none;
            }
        }
    }

    .payment-link > .transaction-view {
        color: $white;
    }

    .payment-link > .payment-button > div > .button--primary {
        background: $color-border--dark;
        font-size: 90%;

        &:after {
            background: $green;
            color: $white;
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 4px;

        > a,
        div {
            padding: 0 15px;
            line-height: 40px;
            height: 39px;
            font-size: 90%;

            .no-touchevents &:hover {
                text-decoration: none;
            }

            span {
                margin-right: 5px;
            }
        }
        &:last-of-type a,
        &:last-of-type div {
            border-bottom: 0;
            box-shadow: none;
        }
    }

    .icn-chevron-r {
        padding-right: 12px;
        color: $white;
    }

    &.is-open {
        opacity: 1;
        animation: fadeInDown 0.4s;
        display: block;
    }
}

.header-bottom {
    color: $colourHeaderTitle;
    position: relative;
    overflow: visible;
    flex: 100%;
    @include mq($from: l) {
        order: 1;
    }
}

.header-nav-sell {
    .btn-header {
        background: $berry;
        border: 1px solid $berry;
    }
}

.header-nav-burger {
    .icn-burger {
        font-size: 16px;
        top: 0px;
        line-height: 1;
    }
}

.header-nav {
    padding: 8px 0;
}

.header-nav-login .icn-person {
    display: none;
}

.header-nav-postad {
    [class^="icn-"] {
        transition: 0.1s ease;
    }

    .no-touchevents &:hover,
    &:focus {
        .nav-label {
            color: $color-text--light;
        }

        [class^="icn-"] {
            transform: none;
            color: $color-text--light;

            &:after {
                background: $color-text--light;
            }
        }

        [class*="green"] {
            color: #68d634 !important;

            &:before {
                color: #68d634;
            }
        }
    }
}

.no-flexbox {
    .header-nav-burger,
    .header-nav-search,
    .header-nav-mygumtree,
    .header-nav-messages,
    .header-nav-login {
        float: right;
    }
}

.header-nav-burger {
    position: relative;

    > a {
        height: 44px;
        width: 44px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        line-height: 1;
    }
}

.header-nav-mygumtree {
    position: relative;
    .header-link {
        display: flex;
        align-content: center;
    }
    .truncate-line {
        max-width: 250px;
    }
    .header-nav-submenu {
        right: 0px;
        min-width: 192px;
    }
}

.header-logo {
    display: flex;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .logo-icon {
        display: block;
        height: 35px;
        width: 29px;

        @include mq($from: s) {
            height: 40px;
            width: 33px;
        }
    }

    .logo-text {
        margin-left: 12px;
        width: 113px;
    }
}

.is-showing-search {
    .header-nav-search {
        position: relative;
        a {
            position: relative;
        }
    }
}

.header-search {
    > form {
        position: relative;
        padding: 0;

        @include mq($from: m) {
            background: $white;
            border-radius: 50px;
            margin-bottom: 8px;
        }

        @include mq($from: l) {
            margin: 8px 0;
        }

        .location-tooltip .tooltip-content {
            width: 250px;
            padding-top: 9px;
            top: 65px;
            left: 0;
            box-shadow: $bark_40pc 0 1px 6px 1px;
            &:after {
                height: 10px;
            }
        }
        &.location-is-focused {
            .location-tooltip .tooltip-content {
                display: block;
                &:before,
                &:after {
                    left: 10%;
                }
            }
        }
    }
}

.header-search-keyword {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    float: left;

    @include mq($from: m) {
        margin-bottom: 0;
    }

    .has-clear {
        > input {
            width: 100%;
        }
    }
}

.header-search-location {
    position: relative;
    padding-right: 41px + $xsSpacingPx;
    width: 100%;
    float: left;
    margin-bottom: 8px;

    @include mq($from: m) {
        margin-bottom: 0;
    }

    .has-clear {
        > input {
            width: 100%;
        }
    }

    label {
        position: absolute;
        top: 8px;
        height: 23px;
        border-right: 1px solid rgba(60, 50, 65, 0.49);
        left: 25px;
    }
}

.header-search-keyword .has-clear,
.header-search-location .has-clear {
    width: 100%;

    @include mq($to: m) {
        .clear-input {
            right: 17px;
        }
    }
}

.header-search-distance {
    padding-right: 0;
    float: left;
    display: block;
    width: 50%;
}

.header-search-submit {
    position: absolute;
    right: 0;
    height: 35px;
    padding: 0 0;

    button {
        border-radius: 50px;
        height: 44px;
        width: 44px;
    }
}

.btn-header-txt-mobile {
    @include mq($from: s) {
        display: none;
    }
}

.header-popular-searches {
    padding: 0 8px 0 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px;
    display: none;

    .popular-searches-wrapper {
        position: relative;
        display: table;
        width: 100%;
        font-size: $font-size--smaller;
        line-height: 1;

        .popular-searches-title {
            padding-top: 3px;
        }
    }

    .media-body-overflow {
        height: 30px;
        overflow: hidden;
        height: initial;
    }

    label {
        display: table-cell;
        color: $bark_40pc;
        height: 100%;
        max-height: 36px;
        cursor: pointer;
        vertical-align: bottom;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 3px;

        &:before,
        &:after {
            font-size: 13px;
            content: "\e617";
            display: block;
            width: 20px;
            text-align: center;
        }
        &:after {
            transform: rotate(180deg);
            margin-top: 0px;
            display: none;
        }
    }

    input {
        position: absolute;
        left: -9999px;
        &:checked {
            & + div {
                height: auto;
            }
            & + div ul {
                max-height: 100%;
            }
            & + div label {
                vertical-align: top;
                &:after {
                    display: block;
                }
                &:before {
                    height: 0;
                    display: none;
                }
            }
        }
    }

    ul {
        @include clearfix-me();
        position: relative;
        list-style: none;
        display: block;
        overflow: hidden;
        width: auto;
        margin-right: 30px;
        color: $bark_40pc;
        max-height: 21px;

        li {
            float: left;
            margin-right: 12px;

            a {
                background: initial;
                color: $bark_40pc;
                padding: 2px 7px;
                border-radius: 25px;
                display: inline-block;
                border: 1px solid $bark_40pc;
                margin-bottom: 8px;

                &,
                &:visited {
                    display: inline;
                }
            }
        }
    }
}

.header-popular-searches-title {
    margin: 1px 0 0 0;
    font-weight: $fontBoldWeight;
    color: $defaultTextColour;
}

.no-js {
    .header .header-nav-submenu,
    .header-nav-burger,
    .header-nav-search {
        display: none;
    }

    .header-search {
        position: static;
    }

    .header-nav-mygumtree-icon {
        display: none;
    }

    .header-sidemenu-title {
        position: static;
        width: 100%;
    }
}

.app-download {
    &-container {
        padding: 10px 20px;
    }

    &-btn {
        width: 100%;

        .icn-download::before {
            color: $colourBtnBackgroundPrimaryGtp2992Navy;
        }
    }
}

.header-search-inputs {
    @include clearfix-me();
}

.header-sidemenu {
    font-weight: $fontBoldWeight;

    .sidemenu-link {
        padding: 10px 20px;
    }
}

.viewport-is-active .header-sidemenu {
    display: block;
}

.is-offset-left .header-sidemenu {
    transform: translateX(0%);

    .ie9 & {
        right: 0;
    }
}

.ie .has-sticky-footer .header-sidemenu-last {
    padding-bottom: 50px;
}

.header-bevel-list-icon {
    margin-right: 10px;
}

.header-bevel-list-icon-sm {
    margin-right: 5px;
}

.header-sidemenu-title {
    margin: 6px 0 0;
    padding-bottom: 7px;
    padding-left: 20px;
    font-size: $fontSizeMicro;
    letter-spacing: $fontSizeMicroKerning;
    color: $colourTxtSecondary;
}

.header-bevel-list {
    background-color: $colourMenuBg;
    color: $colourTxtPrimary;

    .sidemenu-link {
        background-color: $colourMenuBg;
        display: block;
        color: $colourTxtSecondary;

        .no-touchevents &:hover,
        &:focus {
            background-color: $colourMenuBgHover;
            .header-bevel-list-icon {
                color: $colourMenuIcnHover;
            }
        }
    }
}

.header-category-select {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.header-category-select,
.header-location-select {
    float: left;
    display: block;
}

.header-location-select > select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.header-location-select {
    width: 100%;
}

.header-logo-svg-small {
    margin-left: $xsSpacingPx;
    width: 137px;
    height: auto;
}

.header-search-keyword input,
.header-search-location input {
    border: none;
    width: 100%;
    float: left;
    display: block;
    border-radius: 50px;
    padding-left: 17px;
    font-weight: 330;

    &[type="search"]:focus {
        border: none;
    }
}

.header select:focus {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.header-bottom {
    z-index: $z-index--medium-high + 1;

    @include mq($from: m) {
        will-change: transform;
    }

    @include mq($from: l) {
        margin: 0 8px;
    }
}

.header-search {
    display: none;
    margin-top: 0;

    .is-showing-search & {
        display: block;
    }

    @include mq($from: l) {
        display: block;
    }
}

@include mq($to: m) {
    .header-nav > ul {
        align-items: center;
    }

    .media-body-overflow {
        margin-bottom: $xsSpacingPx;
    }

    .header-bottom {
        .is-showing-search & {
            min-height: 103px;
        }
    }

    .header-nav {
        background-color: $blade;
    }

    .header-search-distance {
        padding-right: 50px;
    }

    .header-location-mobile-outer {
        height: 40px;
    }

    .header-location-mobile-container {
        position: relative;
    }

    .header-location-inner {
        display: inline-block;
        max-width: 100%;
    }

    .header-location-name {
        display: block;
        max-width: 100%;
    }

    .header-search-submit {
        bottom: 16px;
    }

    .header-nav-mygumtree,
    .header-nav-login,
    .header-logo-small,
    .header-search-in,
    .header-location {
        display: none;
    }
}

@include mq($from: s) {
    .header-popular-searches {
        margin-left: 17px;
    }
}

@include mq($from: m) {
    .header-nav-search,
    .header-nav-postad,
    .header-nav-messages,
    .header-nav-login,
    .header-nav-mygumtree {
        display: flex;
        align-items: center;
    }

    .header-location {
        float: left;
        margin-left: $mSpacingPx;
    }

    .header-location-inner {
        float: left;
    }

    .header-location-name {
        float: left;
        max-width: 165px;
    }

    .is-open .header-nav-mygumtree-icon {
        transform: rotate(180deg);
        padding-right: 8px;
        padding-left: 0;
    }

    .header-nav-mygumtree-icon {
        font-size: $font-size--medium;
        margin-left: 4px;
    }

    .header-search-keyword {
        width: 54%;
    }

    .header-search-location {
        width: 46%;
        padding-left: 32px;
        padding-right: 50px;
        position: relative;
        margin-right: auto;
        float: left;

        label {
            position: absolute;
            top: 11px;
            left: 9px;
        }
    }

    .header-search-submit {
        top: 0;
    }

    .header-popular-searches {
        margin-bottom: 5px;
    }

    .no-js {
        .header {
            margin-bottom: 0;
        }

        .header-search {
            padding-left: 115px;

            > form {
                padding: 0;
                margin-top: 10px;
            }
        }

        .header-search-submit {
            top: 0;
        }
    }

    .header-nav-burger,
    .header-logo-large,
    .header-sidemenu {
        display: none;
    }
}

@include mq($to: l) {
    .header-sidemenu {
        @include translate3dToggle(
            $ancestorSelector: body,
            $toggleClass: is-offset-left,
            $endX: 0,
            $startX: 280px,
            $zIndex: 1,
            $cancelFrom: m,
            $baseStyles: false
        );
        top: 0;
        position: fixed;
        overflow: scroll;
        bottom: 0;
        width: 270px;
        background-color: $colourMenuBg;
        right: 0;
        padding-bottom: 50px;
        z-index: 602;

        .ie9 & {
            right: -280px !important;
            left: auto !important;
        }

        .ie9 .is-offset-left & {
            right: 0 !important;
        }
    }
}

@include mq($from: l) {
    .header-search {
        padding: 0;
    }

    .header-nav-search {
        display: none;
    }

    .header-search-keyword {
        width: 59%;
    }

    .header-search-location {
        width: 41%;
    }

    .header-popular-searches {
        padding: 0;
        padding-right: 10px;
    }
}

@media all and (min-width: 880px) {
    .header-location-name {
        max-width: 185px;
    }
}

@include mq($from: xl) {
    .header-location-name {
        max-width: 300px;
    }
}

@include mq($from: xxl) {
    .header-search-keyword {
        width: 61%;
    }

    .header-search-location {
        width: 39%;
    }
}

.newNav {
    padding: 0;

    &:hover {
        background: none !important;
    }

    .newNav-wrapper {
        display: flex;
        flex-wrap: wrap;

        @include mq($from: l) {
            flex-wrap: nowrap;
        }
    }

    .header-nav ul {
        align-items: baseline;
    }

    .header-button {
        @extend %header-button;

        .icn-pin,
        .icn-car {
            transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            color: $berry;
        }

        .icn-full-heart-white {
            &:after {
                content: "●";
                position: absolute;
                top: -10px;
                font-size: 26px;
                right: -10px;
                display: block;
                color: $berry;
            }
        }
    }

    .toolbar {
        position: relative;
        display: flex;
        align-items: center;
        flex: auto;
        justify-content: flex-end;
        height: 60px;

        @include mq($from: l) {
            order: 2;
        }

        > li {
            & + li {
                margin-left: 16px;

                @include mq($to: s) {
                    margin-left: 7px;
                }
            }

            & > a {
                @include mq($to: s) {
                    min-width: 44px;
                    font-size: 12px;
                }
            }
        }

        .header-nav-mygumtree {
            .header-link {
                display: flex;
                align-content: center;
                flex-direction: column;
                font-size: 12px;
                line-height: 20px;

                .icn-burger {
                    font-size: 22px;
                }
            }
        }
    }

    .header-sidemenu-experiment {
        display: block;
    }

    .header-nav-mygumtree {
        &.is-open {
            background: $bark_80pc;

            &:after {
                content: "";
                display: block;
                border-left: 10px solid $bark_80pc;
                border-right: 10px solid $bark_80pc;
                height: 100%;
                width: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                box-sizing: content-box;
            }
        }

        @include mq($from: s) {
            height: inherit;
        }
    }

    .header-nav-mygumtree .header-nav-submenu {
        background-color: $bark_80pc;
        color: white;
        min-width: 225px;
        top: 60px;
        padding: 0;
        border: none;
        right: -10px;
    }

    .header-bevel-list .sidemenu-link {
        background-color: $bark_80pc;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .header-bevel-list-icon {
            top: 0;
        }

        &:hover {
            background-color: #8a848d !important;

            .header-bevel-list-icon {
                color: $white !important;
            }
        }
    }

    .nav-label {
        font-size: 12px;
        transition: 0.1s ease;

        &-green {
            color: $leaf;
        }

        @media screen and (min-width: 1024px) {
            line-height: 1.2;
        }
    }

    .header-nav-login {
        flex-direction: column;
        font-size: 12px;
        display: flex;
        align-items: center;

        @include mq($to: m) {
            display: none;
        }

        .icn-person {
            display: block;
            top: 0;
        }
    }

    .header-nav-link {
        line-height: 20px;
        height: auto;
        padding: 0;
    }

    .header-nav-login {
        @include mq($to: s) {
            display: none;
        }
    }

    .header-nav-messages {
        position: relative;
    }
    #header-nav-unread-message-count {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-70%, -35%);
        width: 16px;
        height: 16px;
        background-color: #5cf100;
        border-radius: 50%;
        color: #000;
        font-size: 11px;
        font-weight: normal;
        line-height: 16px;
        vertical-align: middle;
        text-align: center;
    }
}

.car-cat-icon-header {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    flex-direction: column-reverse;
    font-size: 14px;
    height: inherit;
    justify-content: center;
    line-height: 20px;

    .car-icon {
        display: flex !important;
        flex-direction: column-reverse;

        .icn-car {
            padding: 0 !important;
        }
    }
}

.header-nav-burger-experiment {
    display: none;

    > a {
        font-size: 14px;
    }

    @include mq($to: m) {
        display: block;
    }

    @include mq($to: s) {
        > a {
            height: inherit;

            .icn-burger {
                font-size: 22px;
                line-height: 20px;
                top: -4px;
            }
        }
    }
}

.header-sidemenu-experiment {
    @include mq($to: l) {
        background-color: $bark_80pc;
        color: white;
    }
    .header-bevel-list .sidemenu-link {
        background-color: $bark_80pc;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;

        &:hover {
            color: $bark_80pc;
            text-decoration: none;
        }

        .header-bevel-list-icon {
            margin-right: 0;
        }
    }

    .app-download-container {
        padding: 12px 24px 24px 24px;
        background: $bark_80pc;
    }
}

.sidemenu-link form[name="logout-form-desktop"] {
    height: 39px;
    width: 100%;
    display: flex;
    cursor: pointer;

    button {
        all: unset;
        font-size: 13.5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.sidemenu-link form[name="logout-form-mobile"] {
    height: 22px;
    width: 100%;
    display: flex;
    cursor: pointer;

    button {
        all: unset;
        font-size: 15.5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
