// @category Grid
// @name Standard Grid
// @description This is the one
//
// @markup
//     <div class="grid-container">
//        <div class="grid-row">
//             <div class="grid-col-6">.grid-col-6</div>
//             <div class="grid-col-6">.grid-col-6</div>
//             <div class="grid-col-4">.grid-col-4</div>
//             <div class="grid-col-4">.grid-col-4</div>
//             <div class="grid-col-4">.grid-col-4</div>
//         </div>
//     </div>

// @category Grid
// @name Media query powered grid
// @description Grid layout at a specified breakpoint and up using .grid-col{mq name}-{columns used}. Anything below the set mq will resort to 12 columns wide. The below example shows a 2 column grid split into 2 6 columns at small screen sizes, but 8 and 4 at higher screen sizes.
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-s-6 grid-col-xl-8">.grid-col-s-6 .grid-col-xl-8</div>
//             <div class="grid-col-s-6 grid-col-xl-4">.grid-col-s-6 .grid-col-xl-4</div>
//         </div>
//     </div>

// @category Grid
// @name Omega grid column
// @description Forces the column to be floated to the right and take the end slot up. Can be reset at a higher breakpoint with its opposite class .grid-col{mq name}-left. The below example shows the first column being turned into the last.
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-s-6 grid-col-right">Last 6</div>
//             <div class="grid-col-s-6">6</div>
//         </div>
//     </div>

// @category Grid
// @name Omega grid column with mq
// @description Forces the column to be __floated__ to the right and take the end slot up. Can be reset at a higher breakpoint with its opposite class .grid-col-{mq name}-left. The below example shows the first column being made the last column except on larger screens
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-s-6 grid-col-right grid-col-l-left">Last 6 except @ large mq and up</div>
//             <div class="grid-col-s-6">6</div>
//         </div>
//     </div>

// @category Grid
// @name Offest grid columns
// @description Move the start position of columns over by a certain amount of columns
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-6 grid-col-offset-2">6 cols offset by 2</div>
//             <div class="grid-col-4">4 cols</div>
//         </div>
//     </div>

// @category Grid
// @name Offest grid columns with mq
// @description Move the start position of columns over by a certain amount of columns at different mq
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-8 grid-col-xl-6 grid-col-xl-offset-2">6 cols offset by 2 above xl mq</div>
//             <div class="grid-col-4">4 cols</div>
//         </div>
//     </div>

// @category Grid
// @name Pushing and pulling grid columns
// @description Allows you to reorder columns within a grid
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-4 grid-col-push-4">Column 1</div>
//             <div class="grid-col-4 grid-col-pull-4">Column 2</div>
//             <div class="grid-col-4">Column 3</div>
//         </div>
//     </div>

// @category Grid
// @name Pushing and pulling grid columns with mq
// @description Allows you to reorder columns within a grid at different breakpoints
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-4 grid-col-xl-push-4">Column 1</div>
//             <div class="grid-col-4 grid-col-xl-pull-4">Column 2</div>
//             <div class="grid-col-4">Column 3</div>
//         </div>
//     </div>

// @category Grid
// @name Flushing grid columns
// @description Allows you to remove left, right or both gutters on grid columns from specified breakpoint
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-4 grid-s-flush-left">Column 1</div>
//             <div class="grid-col-4 grid-s-flush-right">Column 2</div>
//             <div class="grid-col-4 grid-s-flush-both">Column 3</div>
//         </div>
//     </div>

// @category Grid
// @name Varying Gutters
// @description Allows you to change the guttering between columns
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row grid-row-gutter-xs">
//             <div class="grid-col-4">Column 1</div>
//             <div class="grid-col-4">Column 2</div>
//             <div class="grid-col-4">Column 3</div>
//         </div>
//     </div>

// @category Grid
// @name Padding grid columns
// @description Allows you to add left, right or both gutters on grid columns from specified breakpoint
//
// @markup
//     <div class="grid-container">
//         <div class="grid-row">
//             <div class="grid-col-4 grid-s-gutter-left">Column 1</div>
//             <div class="grid-col-4 grid-s-gutter-right">Column 2</div>
//             <div class="grid-col-4 grid-s-gutter-both">Column 3</div>
//         </div>
//     </div>

// @category Grid
// @name Tiles using the grid
// @description Way to use the grid on a list and effect all the children li's with the same column width
//
// @markup
//     <div class="grid-container">
//         <ul class="grid-row grid-tiles-12 grid-tiles-l-3">
//             <li>li</li>
//             <li>li</li>
//         </ul>
//     </div>

.grid-container {
    @include make-container();
}

// Create media query powered grid containers
@each $breakpoint in $mq-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);
    @if $breakpoint-name != "xs" {
        .grid-container-from-#{$breakpoint-name} {

            @include mq($from: #{$breakpoint-name}) {
                @include make-container-base();
            }

            @if index(("s"), $breakpoint-name) {
                @include make-container-from-s();
            }

            @if index(("s", "m"), $breakpoint-name) {
                @include make-container-from-m();
            }

            @if index(("s", "m", "l"), $breakpoint-name) {
                @include make-container-from-l();
            }

            @if index(("s", "m", "l", "xl"), $breakpoint-name) {
                @include make-container-from-xl();
            }

            @if index(("s", "m", "l", "xl", "xxl"), $breakpoint-name) {
                @include make-container-from-xxl();
            }
        }
    }
}

// Make the grid rows
.grid-row,
[class^="grid-tiles"],
[class*=" grid-tiles"] {
    @include make-row();

    // Align the height of columns within a grid row
    &.align-height {
        display: flex;
        flex-wrap: wrap;
    }
}

// Create the base grid columns
@include make-columns-base();

// Create the grid
@include make-columns-by-mq($mq-breakpoints);

// Allow variable guttering
@include make-variable-guttering();
