// @category Utils
// @name Hide visually
//
// @description Hide element visually but allow screenreaders to read it
//
// @markup
//<p class="hide-visually">Hidden</p>

//TODO : Refactor to not be camel case

.hide-visually {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Extends the .hide-visually class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
.hide-visually.focusable:active,
.hide-visually.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// @category Utils
// @name Hide visually by breakpoint
// @description Hide element visually to m breakpoint but allow screenreaders to read it
//
// @markup
//<p class="hide-visually-mobile-mobilelly">Hidden</p>

//TODO : Make this generate based on mq array
@include mq($to: m) {
    .hide-visually-mobile {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
