// @category Buttons
// @name Navigational button
// @description The navigation button
// @order 8
// @exampleextrasmall
//
// @markup
// <button class="btn-navigation"><span class="icn-chevron-l" aria-hidden="true"></span></button>
// <button class="btn-navigation :disabled"><span class="icn-chevron-r" aria-hidden="true"></span></button>

.btn-navigation {
    @extend %btn;
    color: $colourBtnTextNavigation;
    background-color: $white;
    @include btnHover(transparent, "", $colourBtnTextNavigationHover);

    &.is-disabled {
        border: none;
        background: transparent;
    }

}