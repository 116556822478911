// @category Buttons
// @name Tertiary button
// @description The tertiary button
// @exampleextrasmall
// @state .is-disabled - Disabled version of the button
// @theme dark
// @order 3
//
// @markup
// <button class="btn-tertiary">Click me</button>

.btn-tertiary {
    @extend %btn;
    color: $colourBtnTextTertiary;
    background: $colourBtnBackgroundTertiary;
    @include btnHover($colourBtnBackgroundTertiaryHover);
    border: 1px solid $colourBtnBorderTertiary;
}