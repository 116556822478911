// @category Buttons
// @name Basic button
// @description The basic button
// @exampleextrasmall
// @warning looks very broken, is this ever used at all
// @state .is-disabled - disabled version
//
// @markup
// <button class="btn [state]">Click me</button>

.btn {
    @extend %btn;

    border: none;
    color: $btnDefaultTextColour;
    line-height: 40px;

    .no-touchevents &:hover,
    &:focus {
        color: $btnDefaultHoverTextColour;
    }

    //disabled state of the button
    &.is-disabled, &:disabled {
        pointer-events: none;
        color: $colourBtnDisabledTextColour;
    }
}
